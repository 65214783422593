import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {select, Store} from "@ngrx/store";
import {StepIndicatorModel} from "@app/state/models/add-address.model";
import {sendAccounts, updateCurrentStep} from "../../../state/actions/add-address.action";
import {Observable} from "rxjs";
import {selectSendAccounts, selectStepIndicatorIndex} from "../../../state/selectors/add-address.selector";
import {Account} from "@app/models/thirdparty-documents.model";
import {RadioGroupState} from "@vg-constellation/angular-15/radio";

@Component({
  selector: 'app-brokerage-type',
  templateUrl: './brokerage-type.component.html',
  styleUrls: ['./brokerage-type.component.scss']
})
export class BrokerageTypeComponent implements OnInit, AfterViewInit{
  currentStepIndicatorIndex$: Observable<number>;
  allAccounts$: Observable<Account[]>
  isMutualFundSelected: boolean = false;
  isBrokerageAccountSelected: boolean = false;
  accounts: any;
  hasError: boolean = false;
  pageLoad:boolean;
  private radioState: RadioGroupState;
  constructor(
    private _router: Router,
    private store: Store<StepIndicatorModel>
  ) { }
  ngOnInit(): void {
    this.isMutualFundSelected = false
    this.isBrokerageAccountSelected = false
    // this.currentStepIndicatorIndex$ = this.store.select((state:any) => (state.stepIndicatorReducer.currentStepIndex));
    this.currentStepIndicatorIndex$ = this.store.pipe(select(selectStepIndicatorIndex));
    this.allAccounts$ = this.store.pipe(select(selectSendAccounts))
    this.allAccounts$.pipe().subscribe((data:any) => {
      // console.log(data.accounts)
      this.accounts= JSON.parse(JSON.stringify(data.accounts));
      const brokerageIndex = data.accounts.find((val)=>(val.productType.includes('BROKERAGE') && val.isChecked===true))
      if(brokerageIndex && brokerageIndex !== -1){
        this.isBrokerageAccountSelected = true;
      }
      let mutualFundCounter = 0;
      data.accounts.forEach(val => {
       if(val.positions.find(position => position.isChecked===true)) mutualFundCounter++
      })
      if(mutualFundCounter && mutualFundCounter !==-1) this.isMutualFundSelected = true
      // console.log(mutualFundCounter)
      // console.log(this.isBrokerageAccountSelected)
      // console.log(this.isMutualFundSelected)
    });
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  ngAfterViewInit() {
    this.pageLoad = true;
  }

  goToPage(route?: string) {
    if(this.isBrokerageAccountSelected){
      this.hasError = !this.radioState.hasSelection
    }
    if (route)
      this._router.navigate([route]);
    else if(!this.hasError) {
      this.accounts.forEach(account=>{
        if(account.productType.includes('BROKERAGE') && account.isChecked===true){
          account.optedFor = this.radioState.radioGroupState.inputValue
        }else if(account.productType.includes('MUTUAL_FUND')){
          account.positions.forEach(position=>{
            if(position.isChecked === true) position.optedFor = 'STATEMENTS_CONFIRMATIONS'
          })
        }
      })
      this.store.dispatch(
        sendAccounts({accounts: {accounts:this.accounts}})
      );
      this.store.dispatch(
        updateCurrentStep({currentStepIndex:4})
      );
    }
  }

  radioGroupForBrokerageAccount($event: RadioGroupState) {
    this.radioState = $event
    // console.log($event)
    if(this.pageLoad) this.hasError = !this.radioState.hasSelection;

  }
}
