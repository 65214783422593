import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  private static didRedirectHappened = false;
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      withCredentials: true
  });
    return next.handle(req).pipe(
      catchError((error: any) => {
        // If CORS error - browser cannot read any headers, i.e. `status: 0`.
        // CORS error in VG/Siteminder world means that session is invalid.
        const status = error.status;
        if ( !AuthInterceptor.didRedirectHappened && ( status === 0 || status === 403 ) ) {
          this.doRedirectToLogin();
        }
        // else if (status === 403 && !AuthInterceptor.didRedirectHappened){
        //   this.doRedirectToLogin();
        // }
        return throwError(() => error);
      }),
    );
  }

  doRedirectToLogin(): void {
    AuthInterceptor.didRedirectHappened = true;
    document.location.href = environment.LOGON_URL;
  }
}
