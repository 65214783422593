<div class="container extra-space">
  <div class="step-indicator-container">
    <app-step-indicator [currentStep]="(currentStepIndicatorIndex$ | async) || 5"></app-step-indicator>
  </div>
  <p class="header-label">Confirmation</p>
  <div class="confirm-container">
    <div class="">
      <c11n-icon [name]="'document'"></c11n-icon>
    </div>
    <div class="confirm-number-container">
      <p>Confirmation number {{(confirmationNumber$ | async)}}</p>
    </div>
  </div>
  <div class="title-container">
    <p class="title-text">Thank you. You can print this page for your records.</p>
  </div>

  <div class="new-address-container">
    <p class="new-address-title">Duplicate statement address</p>
    <div class="new-address">
      <p>{{(address$ | async)?.name}}</p>
      <p>{{(address$ | async)?.addressLine1}}<span *ngIf="(address$|async)?.addressLine2">,</span> {{(address$ |
        async)?.addressLine2}}</p>
      <p *ngIf="(address$|async)?.city">{{(address$ | async)?.city}}, {{(address$ | async)?.stateCode}} {{(address$ |
        async)?.zipCode}}<span *ngIf="(address$|async)?.zipCodePlusFour">-</span>{{(address$ | async)?.zipCodePlusFour}}
      </p>
      <p *ngIf="(address$|async)?.foreign">{{(address$ | async)?.foreignCountryCode}}</p>
    </div>
  </div>

  <div class="select-account-container">
    <p class="select-acc-label">Selected accounts</p>
    <div class="accordion-container">
      <div class="row c11n-space-stack-5x">
        <div class="col-12 col-lg-12 col-md-12">
          <ng-container *ngFor="let acc of accounts; index as i">
            <ng-container *ngTemplateOutlet="
              accordion;
              context: {
                size: 'large',
                weight: 'heavy',
                labelText: acc.accountName,
                iconPosition: 'leading',
                summaryText: '',
                isExpanded: true,
                account: acc,
                mainIndex: i
              }
            "></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="button-container">
    <div class="col-4 col-lg-2 col-xl-2 ps-0 mb-3 mb-lg-0 btn-mobile">
      <c11n-button buttonType="button" [size]="'medium'" variant="primary" [labelText]="'Back to duplicate statements'"
        iconName="" colorMode="on-light" [href]="'/'"></c11n-button>
    </div>
  </div>

  <ng-template let-labelText="labelText" let-summaryText="summaryText" let-weight="weight" let-size="size"
    let-isExpanded="isExpanded" let-iconPosition="iconPosition" let-account="account" let-mainIndex="mainIndex"
    #accordion>
    <div class="c11n-accordion c11n-accordion--{{ size }} c11n-accordion--{{
    weight
  }}-weight c11n-accordion--{{ iconPosition }} {{
    isExpanded ? 'c11n-accordion--expanded' : ''
  }} {{ summaryText !== '' ? 'c11n-accordion--summary' : '' }}">

      <h3>
        <button type="button" class="c11n-accordion__trigger" [attr.aria-expanded]="isExpanded" title="show less"
          (click)="handleClick($event)">
          <span class="c11n-accordion__trigger-items">
            <span class="c11n-accordion__heading-group">
              <span class="c11n-accordion__heading">
                <span>
                  <span>{{ labelText }}</span>
                </span>
              </span>
              <span *ngIf="summaryText !== ''" class="c11n-accordion__content">{{ summaryText }}</span>
            </span>
            <ng-container *ngTemplateOutlet="icon"></ng-container>
          </span>
        </button>
      </h3>
      <div class="c11n-accordion__body mt-2">
        <div class="c11n-accordion__body-content">
          <ng-container
            *ngTemplateOutlet="account.brokerageAccountNumber ? brokarageTableBody : mutualFundBody; context: {accountData: account}"></ng-container>
        </div>
      </div>
    </div>
    <ng-template #icon>
      <span *ngIf="size === 'medium'" class="c11n-accordion__icon">
        <svg title="toggle content" class="c11n-caret-down-small">
          <use xlink:href="#caret-down-small"></use>
        </svg>
      </span>
      <span *ngIf="size === 'large'" class="c11n-accordion__icon-container">
        <span class="c11n-accordion__icon">
          <svg title="toggle content" class="c11n-accordion-closed-caret-small">
            <use xlink:href="#accordion-closed-caret-small"></use>
          </svg>
        </span>
      </span>
    </ng-template>
  </ng-template>

  <ng-template #brokarageTableBody let-accountData="accountData">
    <div class="c11n-accordion-story-content">
      <div class="c11n-table__symbols">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 14" width="8" height="14">
          <symbol id="c11n-table-sort-arrow" viewBox="0 0 8 14">
            <path
              d="m.1455522 5.8535523 3.5008945 3.5008941c.195262.1952629.5118446.1952629.7071066 0l3.5008945-3.5008941c.314982-.3149824.091899-.8535533-.3535533-.8535533H.4991056c-.4454524 0-.6685358.5385709-.3535534.8535533z">
            </path>
          </symbol>
        </svg>
      </div>
      <table id="brokarage-sortableTable" class="c11n-table c11n-table--small" aria-label="select account table">
        <thead>
          <tr class="c11n-table__tr">
            <th id="brokarage-col-1-header-2" scope="col" class="c11n-table__thead-th  "
              [attr.aria-sort]="accountData.sortingState">
              <button type="button" class="c11n-link c11n-link--secondary c11n-link--icon c11n-link--normal"
                title="Sortable" aria-label="sorted descending">
                <span class="c11n-link__content">
                  <span class="c11n-link__text">Symbol</span>
                </span>
              </button>
            </th>

            <th id="brokarage-col-2-header-2" scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-left">
              <button type="button" class="c11n-link c11n-link--secondary c11n-link--icon c11n-link--normal"
                title="Sortable" aria-label="sortable">
                <span class="c11n-link__content">
                  <span class="c11n-link__text">Name</span>
                </span>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let brokarageData of accountData.positions">
          <tr class="c11n-table__tr" *ngIf="brokarageData.ticker">
            <td scope="row" class="c11n-table__td c11n-table__tbody-th c11n-table__td--sortable"><span
              class="symbol-text">{{brokarageData?.ticker}}</span></td>
            <td class="c11n-table__td c11n-table__td--align-left c11n-table__td--sortable account-name">
              {{brokarageData?.securityName}}</td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
  </ng-template>

  <ng-template #mutualFundBody let-accountData="accountData">
    <div class="c11n-accordion-story-content">
      <div class="c11n-table__symbols">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 14" width="8" height="14">
          <symbol id="c11n-table-sort-arrow" viewBox="0 0 8 14">
            <path
              d="m.1455522 5.8535523 3.5008945 3.5008941c.195262.1952629.5118446.1952629.7071066 0l3.5008945-3.5008941c.314982-.3149824.091899-.8535533-.3535533-.8535533H.4991056c-.4454524 0-.6685358.5385709-.3535534.8535533z">
            </path>
          </symbol>
        </svg>
      </div>
      <table id="mutualfund-sortableTable" class="c11n-table c11n-table--small" aria-label="select account table">
        <thead>
          <tr class="c11n-table__tr">
            <th id="mutualfund-col-1-header-2" scope="col"
              class="c11n-table__thead-th c11n-table__thead-th--align-left">
              <button type="button" class="c11n-link c11n-link--secondary c11n-link--icon c11n-link--normal"
                title="Sortable" aria-label="sorted descending">
                <span class="c11n-link__content">
                  <span class="c11n-link__text">Fund & Account</span>
                  <!-- <svg viewBox="0 0 8 14" aria-hidden="true">
                    <use xlink:href="#c11n-table-sort-arrow"></use>
                    <use xlink:href="#c11n-table-sort-arrow"></use>
                  </svg> -->
                </span>
              </button>
            </th>

            <th id="mutualfund-col-2-header-2" scope="col"
              class="c11n-table__thead-th c11n-table__thead-th--align-left">
              <button type="button" class="c11n-link c11n-link--secondary c11n-link--icon c11n-link--normal"
                title="Sortable" aria-label="sortable">
                <span class="c11n-link__content">
                  <span class="c11n-link__text">Name</span>
                </span>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="c11n-table__tr" *ngFor="let mutualFund of accountData.positions">
            <td scope="row"
              class="c11n-table__td  c11n-table__td--align-left c11n-table__tbody-th c11n-table__td--sortable">
              <span class="symbol-text">{{mutualFund?.vanguardFundId}}-{{mutualFund?.vastAccountNumber}}</span>
            </td>
            <td class="c11n-table__td c11n-table__td--align-left c11n-table__td--sortable account-name">
              {{mutualFund?.securityName}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-template>
</div>
