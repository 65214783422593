import { createAction, props } from '@ngrx/store';
import {AccountsModel, AddressModel, USStates, CountriesList} from "@app/state/models/add-address.model";

export enum StepIndicatorActionType {
  CURRENT_STEP = '[Step Indicator] Current Step'
}
export enum AddAddressActionType {
  ADDRESS_ENTERED = '[Add Address] Address Entered',
  LOAD_US_STATES = '[Add Address] Load US States',
  LOAD_US_STATES_SUCCESS = '[Add Address] Load US States Success',
  LOAD_COUNTRIES_LIST = '[Add Address] Load Countries List',
  LOAD_COUNTRIES_LIST_SUCCESS = '[Add Address] Load Countries List Success',
  CONFIRMATION_NUMBER = '[Add Address] Confirmation Number'

}
export enum SendAccountsActionType {
  ALL_ACCOUNTS = '[Accounts] All Accounts'
}
export const updateCurrentStep = createAction(StepIndicatorActionType.CURRENT_STEP, props<{currentStepIndex: number}>());

export const updateAddress = createAction(AddAddressActionType.ADDRESS_ENTERED, props<{address: AddressModel}>());

export const sendAccounts = createAction(SendAccountsActionType.ALL_ACCOUNTS, props<{accounts: AccountsModel}>());

export const loadUSStates = createAction(AddAddressActionType.LOAD_US_STATES);

export const loadUSStatesSuccess = createAction(AddAddressActionType.LOAD_US_STATES_SUCCESS, props<{allUSStates: USStates[]}>());

export const loadCountiresList = createAction(AddAddressActionType.LOAD_COUNTRIES_LIST);

export const loadCountiresListSuccess = createAction(AddAddressActionType.LOAD_COUNTRIES_LIST_SUCCESS, props<{countriesList: CountriesList[]}>());

export const updateConfirmationNumber = createAction(AddAddressActionType.CONFIRMATION_NUMBER, props<{confirmationNumber: string}>());
