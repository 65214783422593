<div class="w-100 p-5 row" *ngIf='shouldShowErrorBannerForCaas'>
    <c11n-banner class="caas-error-banner" [size]="'medium'" [hasCloseControl]="hasCloseControl"
        [hasDetails]="hasDetails" [variant]="'error'" [headingText]="contentBodyForCaasError"
        [attr.aria-label]="contentBodyForCaasError" tabIndex="0">
    </c11n-banner>
</div>
<div class="container" *ngIf='!shouldShowErrorBannerForCaas'>
    <div class="row breadcrumb-container">
        <div class="col-12">
            <a class="breadcrumb-first-section"
                [href]="navigationContent[0].href">{{navigationContent[0].labelText}}</a>
            <span class="d-inline-block mx-3">
                <c11n-icon name="caret-right" size="small" scale="normal" altText=""></c11n-icon>
            </span>
            <span class="breadcrumb-last-section" tabindex="0"
                attr.aria-label="{{navigationContent[1].labelText}} current"
                role="link">{{navigationContent[1].labelText}}</span>
        </div>
    </div>
    <div class="row mb-5 header-wrapper">
        <div class="col-12 col-md-9 ps-0">
            <h1 class="duplicate-statement-header">{{thirdpartyDocumentsCaasContent.pageTitle}}</h1>
        </div>
    </div>

    <c11n-spinner *ngIf="isLoaderIndicate" class="spinner-on-screen" size="medium" colorMode="on-light"
        spinnerStatus="Loading...">
    </c11n-spinner>

    <div class="address-list-section">
        <div class="w-100 mb-5 row" *ngIf='shouldShowErrorBanner'>
            <c11n-banner class="error-banner" [size]="'medium'" [hasCloseControl]="hasCloseControl"
                [hasDetails]="hasDetails" [variant]="'error'" [headingText]="contentBody"
                [attr.aria-label]="contentBody" tabIndex="0">
            </c11n-banner>
        </div>
        <div class="w-100 p-4 p-md-5 mb-5 row address-wrapper" id="addr-container-{{i+1}}"
            *ngFor="let address of thirdPartyDocAddressList; index as i;">
            <div class="col-6 order-1 col-md-1 p-sm-0">
                <div class="circle-div">
                    <!--                    <c11n-icon name="document">-->
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M20.8758 7.05279L14.3043 0.481357C13.9963 0.17324 13.5785 0 13.1428 0H4.92854C3.11389 0 1.64282 1.47106 1.64282 3.28571V19.7143C1.64282 21.5289 3.11389 23 4.92854 23H18.0714C19.886 23 21.3571 21.5289 21.3571 19.7143V8.21429C21.3571 7.77861 21.1839 7.36081 20.8758 7.05279ZM5.33925 19.7143C5.11242 19.7143 4.92854 19.5304 4.92854 19.3036V3.69643C4.92854 3.4696 5.11242 3.28571 5.33925 3.28571H11.0893C11.3161 3.28571 11.5 3.4696 11.5 3.69643V8.21429C11.5 9.12161 12.2355 9.85714 13.1428 9.85714H17.6607C17.8875 9.85714 18.0714 10.041 18.0714 10.2679V19.3036C18.0714 19.5304 17.8875 19.7143 17.6607 19.7143H5.33925Z"
                            fill="#040505" />
                    </svg>
                    <!--                    </c11n-icon>-->
                </div>
            </div>
            <div class="col-12 order-3 col-md-4 order-md-2 mt-3 mt-md-0">
                <p class="name-style" tabindex="0" attr.aria-label="Row {{i+1}} name {{address.name}}">
                    {{address?.name}}</p>
                <p class="name-subtext">Duplicate statement recipients will receive account statements and transaction
                    confirmations.</p>
            </div>
            <div class="col-12 order-4 col-md-4 order-md-3 mt-3 mt-md-0 ps-md-5 pb-3 pb-md-0">
                <p class="address-label">Address</p>
                <p class="address-text w-75 pe-3" tabindex="0"
                    attr.aria-label="Row {{i+1}} address
                    {{address?.addressLine1}} {{address?.addressLine2}} {{address?.addressLine3}} {{address?.addressLine4}} {{address?.city}}
                    {{address?.stateCode}} {{address?.zipCode}}{{(!address?.foreign && address?.zipCodePlusFour) ? '-' +
                    address.zipCodePlusFour : ''}} {{(address?.foreign && address?.foreignCountryCode) ? address.foreignCountryCode : ''}}">
                    {{address?.addressLine1}}
                    {{address?.addressLine2}}
                    {{address?.addressLine3}}
                    {{address?.addressLine4}}
                    {{address?.city}}
                    {{address?.stateCode}}
                    {{address?.zipCode}}{{(!address?.foreign && address?.zipCodePlusFour) ? '-' +
                    address.zipCodePlusFour : ''}}
                    {{(address?.foreign && address?.foreignCountryCode) ? address.foreignCountryCode : ''}}
                </p>
            </div>
<!--            <div class="col-6 order-2 col-md-3 order-md-4 text-end pt-3 pt-md-0 ps-md-5">-->
<!--                <c11n-nav-overflow direction="below" align="end" [menuData]="actionMenuData"-->
<!--                    [externalLabel]="showActionLabel" [autoWidth]="false">-->
<!--                </c11n-nav-overflow>-->
<!--            </div>-->
          <div class="col-6 order-2 col-md-3 order-md-4 text-end pt-3 pt-md-0 ps-md-5">
          <div class="menu-stories">
            <div class="component-wrapper">
              <div class="c11n-nav-overflow c11n-nav-overflow--z-index-800 c11n-nav-overflow--end c11n-nav-overflow--below">
                <span class="c11n-nav-overflow__label"></span>
                <button (click)="handleClick($event)" class="c11n-nav-overflow__trigger c11n-menu__trigger" type="button" aria-label="overflow menu" aria-controls="overflow-menu-controls-1" aria-expanded="false">
                  <svg class="c11n-overflow-menu-small" focusable="false" aria-hidden="true">
                    <use xlink:href="#overflow-menu-small"></use>
                  </svg>
                </button>
                <div class="c11n-nav-overflow__menu c11n-menu__menu" id="overflow-menu-controls-1">
                  <ul role="list" class="c11n-nav-overflow__group c11n-menu__group">
                    <li class="c11n-nav-overflow__li">
                      <a role="button" (click)="changeAddress(address)" class="c11n-nav-overflow__a c11n-menu__item"><span class="c11n-menu__item-label">Change address and its accounts</span></a>
                    </li>
                    <li class="c11n-nav-overflow__li">
                      <a role="button" (click)="deleteAddress(address)"  class="c11n-nav-overflow__a c11n-menu__item"><span class="c11n-menu__item-label">Delete this address</span></a>
                    </li>
                    <li class="c11n-nav-overflow__li">
                      <a role="button" (click)="viewAccounts(address)" class="c11n-nav-overflow__a c11n-menu__item"><span class="c11n-menu__item-label">View accounts that use this address</span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          </div>



        </div>

        <!-- Special Payee Address list section -->

        <div *ngIf="thirdPartySpecialPayeeAddressList.length">
            <div class="row mb-4">
                <h3 class="ps-0 fw-bold special-payee-banner" tabindex="0">{{thirdpartyDocumentsCaasContent.payeeTitle}}</h3>
            </div>
            <div class="w-100 p-4 p-md-5 mb-5 row address-wrapper" id="special-payee-container-{{i+1}}"
                *ngFor="let address of thirdPartySpecialPayeeAddressList; index as i;">
                <div class="col-6 order-1 col-md-1 p-sm-0">
                    <div class="circle-div">
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M20.8758 7.05279L14.3043 0.481357C13.9963 0.17324 13.5785 0 13.1428 0H4.92854C3.11389 0 1.64282 1.47106 1.64282 3.28571V19.7143C1.64282 21.5289 3.11389 23 4.92854 23H18.0714C19.886 23 21.3571 21.5289 21.3571 19.7143V8.21429C21.3571 7.77861 21.1839 7.36081 20.8758 7.05279ZM5.33925 19.7143C5.11242 19.7143 4.92854 19.5304 4.92854 19.3036V3.69643C4.92854 3.4696 5.11242 3.28571 5.33925 3.28571H11.0893C11.3161 3.28571 11.5 3.4696 11.5 3.69643V8.21429C11.5 9.12161 12.2355 9.85714 13.1428 9.85714H17.6607C17.8875 9.85714 18.0714 10.041 18.0714 10.2679V19.3036C18.0714 19.5304 17.8875 19.7143 17.6607 19.7143H5.33925Z"
                                fill="#040505" />
                        </svg>
                    </div>
                </div>
                <div class="col-12 order-3 col-md-4 order-md-2 mt-3 mt-md-0">
                    <p class="name-style" tabindex="0" attr.aria-label="Row {{i+1}} name {{address.name}}">
                        {{address?.name}}</p>
                    <p class="name-subtext">Duplicate statement recipients will receive account statements and
                        transaction
                        confirmations.</p>
                </div>
                <div class="col-12 order-4 col-md-4 order-md-3 mt-3 mt-md-0 ps-md-5 pb-3 pb-md-0">
                    <p class="address-label">Address</p>
                    <p class="address-text w-75 pe-3" tabindex="0"
                        attr.aria-label="Row {{i+1}} address
                    {{address?.addressLine1}} {{address?.addressLine2}} {{address?.addressLine3}} {{address?.addressLine4}} {{address?.city}}
                    {{address?.stateCode}} {{address?.zipCode}}{{(!address?.foreign && address?.zipCodePlusFour) ? '-' +
                    address.zipCodePlusFour : ''}} {{(address?.foreign && address?.foreignCountryCode) ? address.foreignCountryCode : ''}}">
                        {{address?.addressLine1}}
                        {{address?.addressLine2}}
                        {{address?.addressLine3}}
                        {{address?.addressLine4}}
                        {{address?.city}}
                        {{address?.stateCode}}
                        {{address?.zipCode}}{{(!address?.foreign && address?.zipCodePlusFour) ? '-' +
                        address.zipCodePlusFour : ''}}
                        {{(address?.foreign && address?.foreignCountryCode) ? address.foreignCountryCode : ''}}
                    </p>
                </div>
                <div class="col-6 order-2 col-md-3 order-md-4 text-end pt-3 pt-md-0 ps-md-5">
                    <c11n-nav-overflow direction="below" align="end" [menuData]="actionMenuData"
                        [externalLabel]="showActionLabel" [autoWidth]="false">
                    </c11n-nav-overflow>
                </div>
            </div>
        </div>
    </div>

    <div class="what-need-to-know row">
        <div class="col-12 query-header-container ps-0">
            <h3 class="query-section-header">{{thirdpartyDocumentsCaasContent.faqTitle}}</h3>
        </div>
        <div class="row description-container" *ngFor="let content of faqContent; index as j;">
            <div class="number-circle col-2 number-{{j+1}}" *ngIf="j!==3">{{content.optionKey}}</div>
          <div class="number-circle col-2 number-{{j+1}}" *ngIf="j ===3 && is529BtnEnabled">{{content.optionKey}}</div>
            <div class="col-10 col-md-8 ps-0">
              <p class="query-section-description query-section-{{j+1}}" *ngIf="j!==3">{{content.optionValue}}</p>
              <p class="query-section-description query-section-{{j+1}}" *ngIf="j ===3 && is529BtnEnabled">{{content.optionValue}}</p>
            </div>
        </div>
    </div>

  <p class="ftn-message" *ngIf="is529BtnEnabled">{{ftnMessage}}</p>

  <div class="row btn-container">
        <div class="col-12 col-lg-5 col-xl-4 ps-0 mb-3 mb-lg-0 btn-mobile">
            <c11n-button buttonType="button" [size]="isSmallButton ? 'small' : 'large'" variant="primary"
                [labelText]="actionButtonsContent[0].labelText" (clickEvent)="actionSelected()"
                iconName="" colorMode="on-light"></c11n-button>
        </div>
        <div class="col-12 col-lg-7 col-xl-8 ps-0 btn-mobile" *ngIf="is529BtnEnabled">
            <c11n-button buttonType="button" [size]="isSmallButton ? 'small' : 'large'" variant="secondary"
                [labelText]="actionButtonsContent[1].labelText" (clickEvent)="openWindow(actionButtonsContent[1].href)"
                iconName="" colorMode="on-light"></c11n-button>
        </div>
    </div>
  <app-optimize-your-account
        [thirdpartyDocumentsCaasContent]="thirdpartyDocumentsCaasContent"></app-optimize-your-account>
</div>
