export const content = {
    'ADD_ADDRESS': 'Add Address',
    'EDIT_ADDRESS': 'Edit Address',
    'STEP1_INFO':'If you want us to begin mailing duplicate statements to another recipient, enter the name and address information below. On the next screen, you can select the accounts or holdings that apply.',
    'STEP1_NON_US_ADDR': 'Enter a non-U.S. address »',
    'STEP1_US_ADDR': 'Enter a U.S. address »',
    'ADDR_LABEL': 'Address',
    'NAME_Label': 'Name',
    'ADDR_LINE2_LABEL': 'Address line 2 (optional)',
    'CITY_LABEL': 'City',
    'ZIP_CODE_LABEL': 'Zip code',
    'COUNTRY_LABEL':'Country',
}