<p class="optimize-your-account">{{thirdpartyDocumentsCaasContent.optimizeLabel}}</p>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-4">
      <c11n-card
        [density]="density"
        [hasImage]="hasImage"
        [imageAspectRatio]="imageAspectRatio"
        [imagePadding]="imagePadding"
        [hasIcon] = "hasIcon"
        class="card-style-1"
      >
        <img cardImage class="card-img-1" src="/images/enroll-in-edelivery.png" alt="Enroll in e-delivery">
        <h2 class="card-heading" cardHeading>{{optimizeTilesContent[0].tileTitle}}</h2>
        <p class="card-text" cardBody [innerHTML]="optimizeTilesContent[0].tileText"></p>
        <a class="card-link" c11n-link cardAction [href]="optimizeTilesContent[0].linkPath">
          {{optimizeTilesContent[0].linkLabel}}
        </a>
      </c11n-card>
    </div>
    <div class="col-sm-4">
      <c11n-card
        [density]="density"
        [hasImage]="hasImage"
        [imageAspectRatio]="imageAspectRatio"
        [imagePadding]="imagePadding"
        [hasIcon] = "hasIcon"
        class="card-style-2 extra-gap"
      >
        <img cardImage class="card-img-2" src="/images/view-statements.png" alt="View statements">
        <h2 class="card-heading" cardHeading>{{optimizeTilesContent[1].tileTitle}}</h2>
        <p class="card-text" cardBody [innerHTML]="optimizeTilesContent[1].tileText"></p>
        <a class="card-link" c11n-link cardAction [href]="optimizeTilesContent[1].linkPath">
          {{optimizeTilesContent[1].linkLabel}}
        </a>
      </c11n-card>
    </div>
    <div class="col-sm-4">
      <c11n-card
        [density]="density"
        [hasImage]="hasImage"
        [imageAspectRatio]="imageAspectRatio"
        [imagePadding]="imagePadding"
        [hasIcon] = "hasIcon"
        class="card-style-3 extra-gap"
      >
        <img cardImage class="card-img-3" src="/images/visit-tax-center.png" alt="Visit tax center">
        <h2 class="card-heading" cardHeading>{{optimizeTilesContent[2].tileTitle}}</h2>
        <p class="card-text" cardBody [innerHTML]="optimizeTilesContent[2].tileText"></p>
        <a class="card-link" c11n-link cardAction [href]="optimizeTilesContent[2].linkPath">
          {{optimizeTilesContent[2].linkLabel}}
        </a>
      </c11n-card>
    </div>
  </div>
</div>
