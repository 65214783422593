import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-step-indicator',
  templateUrl: './step-indicator.component.html',
  styleUrls: ['./step-indicator.component.scss'],
})

export class StepIndicatorComponent {
  stepLabels = [
    "Enter recipient information",
    "Select accounts/funds",
    "Brokerage type",
    "Review & Submit",
    "Confirmation"
  ];
  @Input() currentStep: number;
}
