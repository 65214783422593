<div class="container extra-space">
  <div class="step-indicator-container">
    <app-step-indicator [currentStep]="(currentStepIndicatorIndex$ | async) || 1"></app-step-indicator>
  </div>

  <p class="header-label">{{title}}</p>
  <a c11n-link type="button" class="address-text-1" (click)="showUsAddress = !showUsAddress">
    <ng-template [ngIf]="showUsAddress">{{content.STEP1_NON_US_ADDR}}</ng-template>
    <ng-template [ngIf]="!showUsAddress">{{content.STEP1_US_ADDR}}</ng-template>
  </a>
  <p class="description-text">{{content.STEP1_INFO}}</p>

  <div class="add-address-container">
    <form (ngSubmit)="addUserAddress(addressForm)" #addressForm="ngForm">
      <div class="col-12 col-sm-12 col-lg-12 col-xl-12 ">
        <label c11n-label for="firstname">{{content.NAME_Label}}</label>
        <c11n-input
          [hasError]="checkValid(!firstname?.valid && firstname?.touched || !firstname?.valid && addressForm?.submitted)">
          <input c11nInput type="text" inputmode="text" id="firstname" name="firstname" ngModel
            aria-describedby="First Name" placeholder="" required #firstname="ngModel" pattern="[a-zA-Z0-9\s]{1,30}"
            maxlength="30">
        </c11n-input>
        <c11n-hint-error
          [hasError]="checkValid(!firstname?.valid && firstname?.touched || !firstname?.valid && addressForm?.submitted)"
          hintText=""
          [errorText]="firstname.errors?.required ? 'Enter the name in the first space provided.' : firstname.errors?.pattern ? 'Special characters are not allowed in first name' : 'Special characters are not allowed in first name'"
          [hintErrorId]="'firstname-error'"></c11n-hint-error>
      </div>
      <!-- <div class="col-12 col-sm-6 col-lg-6 col-xl-6 lastname-container">
          <label c11n-label for="lastname">Last Name</label>
          <c11n-input
            [hasError]="checkValid(!lastname?.valid && lastname?.touched || !lastname?.valid && addressForm?.submitted)">
            <input c11nInput type="text" inputmode="text" id="lastname" name="lastname" ngModel
              aria-describedby="Last Name" placeholder="" required #lastname="ngModel" maxlength="30"
              pattern="[a-zA-Z0-9\s]{1,30}">
          </c11n-input>
          <c11n-hint-error
            [hasError]="checkValid(!lastname?.valid && lastname?.touched || !lastname?.valid && addressForm?.submitted)"
            hintText="" errorText="Last Name is required" [hintErrorId]="'lastname-error'"></c11n-hint-error>
        </div>
      </div>-->

      <div class="row addr-1-container">
        <div class="col-12 col-sm-12 col-lg-12 col-xl-12 ">
          <label c11n-label for="address1">{{content.ADDR_LABEL}}</label>
          <c11n-input
            [hasError]="checkValid(!address1?.valid && address1?.touched || !address1?.valid && addressForm?.submitted)">
            <input c11nInput type="text" inputmode="text" id="address1" name="address1" ngModel
              aria-describedby="Address Line 1" placeholder="" required #address1="ngModel" maxlength="30"
              pattern="[a-zA-Z0-9\s]{1,30}">
          </c11n-input>
          <c11n-hint-error
            [hasError]="checkValid(!address1?.valid && address1?.touched || !address1?.valid && addressForm?.submitted)"
            hintText=""
            [errorText]="address1.errors?.required ? 'Enter the first line of the address in the first space provided.' : address1.errors?.pattern ? 'Special characters are not allowed in address field' : 'Special characters are not allowed in address field'"
            [hintErrorId]="'address1-error'"></c11n-hint-error>
        </div>
      </div>

      <div class="row addr-2-container">
        <div class="col-12 col-sm-12 col-lg-12 col-xl-12 ">
          <label c11n-label for="address2">{{content.ADDR_LINE2_LABEL}}</label>
          <c11n-input
            [hasError]="checkValid(!address2?.valid && address2?.touched || !address2?.valid && addressForm?.submitted)">
            <input c11nInput type="text" inputmode="text" id="address2" name="address2" ngModel
              aria-describedby="Address Line 2 (Optional)" placeholder="" #address2="ngModel" maxlength="30"
              pattern="[a-zA-Z0-9\s/:]{1,30}">
          </c11n-input>
          <c11n-hint-error
            [hasError]="checkValid(!address2?.valid && address2?.touched || !address2?.valid && addressForm?.submitted)"
            hintText=""
            [errorText]="address2.errors?.pattern ? 'Special characters are not allowed in address field' : ''"
            [hintErrorId]="'address2-error'"></c11n-hint-error>
        </div>
      </div>

      <div class="row addr-2-container" *ngIf="showUsAddress">
        <div class="col-12 col-sm-6 city-container">
          <label c11n-label for="city">{{content.CITY_LABEL}}</label>
          <c11n-input [hasError]="checkValid(!city?.valid && city?.touched || !city?.valid && addressForm?.submitted)">
            <input c11nInput type="text" inputmode="text" id="city" name="city" aria-describedby="City" ngModel
              placeholder="" required #city="ngModel" maxlength="16" pattern="[a-zA-Z\s]{1,16}">
          </c11n-input>
          <c11n-hint-error
            [hasError]="checkValid(!city?.valid && city?.touched || !city?.valid && addressForm?.submitted)" hintText=""
            [errorText]="city.errors?.required ? 'Enter the City.' : city.errors?.pattern ? 'Enter a valid city.' : 'Enter a valid city.'"
            [hintErrorId]="'city-error'">
          </c11n-hint-error>
        </div>

        <div class="col-6 col-sm-3">
          <!-- <label c11n-label for="state">State</label>
          <c11n-input
            [hasError]="checkValid(!state?.valid && state?.touched || !state?.valid && addressForm?.submitted)">
            <input c11nInput type="text" inputmode="text" id="state" name="state" ngModel aria-describedby="State"
              placeholder="" required #state="ngModel">
          </c11n-input>
          <c11n-hint-error
            [hasError]="checkValid(!state?.valid && state?.touched || !state?.valid && addressForm?.submitted)"
            hintText="Eg. NC" errorText="State is required" [hintErrorId]="'state-error'"></c11n-hint-error>         -->
          <c11n-select [labelText]="'State'" hintText="" [errorText]="'Required'"
            [hasError]="checkValid(!state?.valid && state?.touched || !state?.valid && addressForm?.submitted)">
            <select c11nSelectInput id="state" name="state" ngModel #state="ngModel" required>
              <option value="" data-option="placeholder" selected disabled>Choose one</option>
              <option [value]="state['stateCode']" *ngFor="let state of (usStateList$ | async)">{{state['longName']}}
              </option>
            </select>
          </c11n-select>

        </div>

        <div class="col-6 col-sm-3">
          <label c11n-label for="city">{{content.ZIP_CODE_LABEL}}</label>
          <c11n-input
            [hasError]="checkValid(!zipcode?.valid && zipcode?.touched || !zipcode?.valid && addressForm?.submitted)">
            <input c11nInput type="text" inputmode="numeric" id="zipcode" name="zipcode" ngModel
              aria-describedby="Zip code" placeholder="" [unmask]="true" [imask]="myZipMask" required #zipcode="ngModel"
              pattern="[0-9]{5,9}">
          </c11n-input>
          <c11n-hint-error
            [hasError]="checkValid(!zipcode?.valid && zipcode?.touched || !zipcode?.valid && addressForm?.submitted)"
            hintText="" [errorText]="'Enter a zip code in one of these formats: xxxxx or xxxxx-xxxx.'"
            [hintErrorId]="'zipcode-error'"></c11n-hint-error>
        </div>
      </div>

      <div class="row country-container" *ngIf="!showUsAddress">
        <div class="col-12 col-sm-12 col-lg-12 col-xl-12 ">
          <c11n-select labelText="{{content.COUNTRY_LABEL}}" hintText="" [errorText]="'Please select a country'"
            [hasError]="checkValid(!country?.valid && country?.touched || !country?.valid && addressForm?.submitted)">
            <select c11nSelectInput id="country" name="country" ngModel #country="ngModel" required>
              <option value="" data-option="placeholder" selected disabled>Choose one</option>
              <option [value]="[country.countryCode,country.longName]" *ngFor="let country of (countryList$ | async)">
                {{country.longName}}</option>
            </select>
          </c11n-select>
        </div>
      </div>

      <div class="row btn-container">
        <div class="col-4 col-lg-2 col-xl-2 ps-0 mb-3 mb-lg-0 btn-mobile">
          <c11n-button [isInvalid]="true" buttonType="submit" [size]="'small'" variant="primary"
            [labelText]="'Continue'" iconName="" colorMode="on-light"></c11n-button>
        </div>
        <div class="col-4 col-lg-2 col-xl-2 ps-0 btn-mobile">
          <c11n-button buttonType="button" [size]="'small'" variant="secondary" [labelText]="'Cancel'"
            (clickEvent)="goToPage('home')" iconName="" colorMode="on-light"></c11n-button>
        </div>
      </div>
    </form>
  </div>
  <!-- <div class="row btn-container">
    <div class="col-4 col-lg-2 col-xl-2 ps-0 mb-3 mb-lg-0 btn-mobile">
      <c11n-button [isInvalid]="true" buttonType="submit" [size]="'small'" variant="primary" [labelText]="'Continue'"
        (clickEvent)="addUserAddress(addressForm)" iconName="" colorMode="on-light"></c11n-button>
    </div>
    <div class="col-4 col-lg-2 col-xl-2 ps-0 btn-mobile">
      <c11n-button buttonType="button" [size]="'small'" variant="secondary" [labelText]="'Cancel'"
        (clickEvent)="goToPage('')" iconName="" colorMode="on-light"></c11n-button>
    </div>
  </div> -->
</div>
