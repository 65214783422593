<div class="container extra-space">
  <div class="step-indicator-container">
    <app-step-indicator [currentStep]="(currentStepIndicatorIndex$ | async) || 3"></app-step-indicator>
  </div>
  <p class="header-label">What do you want to send?</p>
  <table id="brokerage-sortableTable" class="c11n-table c11n-table--small table-margin-top" aria-label="Select brokerage type for Mutual funds" *ngIf="isBrokerageAccountSelected">
    <thead>
    <tr class="c11n-table__tr">
      <th id="brokarage-col-1-header-2" scope="col" class="c11n-table__thead-th table-header-label">
        For your brokerage accounts
      </th>
    </tr>
    </thead>
    <tbody>

    <tr class="c11n-table__tr table-row-no-highlight">
      <td scope="row" class="table-data">
        <c11n-radio-group class="brokerage-radio" [isDisabled]="false" [errorText]="'Please make a selection to continue'" [hideLegend]="true" [legendText]="'Radio group'" [hasError]="hasError"
                          (stateChange)="radioGroupForBrokerageAccount($event)" [variant]="'default'" [hasCustomHintError]="false">
          <c11n-radio [focusLabel]="false" labelText="Duplicate statements only">
            <input c11nRadioInput type="radio" name="choices1" value="STATEMENTS"/>
          </c11n-radio>
          <c11n-radio [focusLabel]="false" labelText="Duplicate confirmations only">
            <input c11nRadioInput  type="radio" name="choices1" value="CONFIRMATIONS"/>
          </c11n-radio>
          <c11n-radio [focusLabel]="false" labelText="Duplicate statements and confirmations">
            <input c11nRadioInput type="radio" name="choices1" value="STATEMENTS_CONFIRMATIONS"/>
          </c11n-radio>
        </c11n-radio-group>

      </td>
    </tr>
    </tbody>
  </table>
  <table id="mutual-fund-sortableTable" class="c11n-table c11n-table--small table-margin-top" aria-label="Select brokerage type for Brokerage accounts" *ngIf="isMutualFundSelected">
    <thead>
    <tr class="c11n-table__tr">
      <th  scope="col" class="c11n-table__thead-th table-header-label">
        For your mutual fund accounts, both statement and confirmations will be sent
      </th>
    </tr>
    </thead>
    <tbody>

    <tr class="c11n-table__tr table-row-no-highlight">
      <td scope="row" class="table-data">
        <c11n-radio-group class="brokerage-radio" [isDisabled]="false" [errorText]="'Please make a selection to continue'" [hideLegend]="true" [legendText]="'Radio group'" [hasError]="false"
                          [variant]="'default'" [hasCustomHintError]="false">
          <c11n-radio [focusLabel]="false" labelText="Duplicate statements and confirmations">
            <input c11nRadioInput id="radio3" type="radio" name="choices" value="STATEMENTS_CONFIRMATIONS" [defaultChecked]="isMutualFundSelected"/>
          </c11n-radio>
        </c11n-radio-group>

      </td>
    </tr>
    </tbody>
  </table>

  <div class="row btn-container">
    <div class="col-4 col-lg-2 col-xl-2 ps-0 mb-3 mb-lg-0 btn-mobile margin-left-10">
      <c11n-button buttonType="button" [size]="'small'" variant="primary"
                   [labelText]="'Continue'" (clickEvent)="goToPage()"
                   iconName="" colorMode="on-light"></c11n-button>
    </div>
    <div class="col-4 col-lg-2 col-xl-2 ps-0 btn-mobile">
      <c11n-button buttonType="button" [size]="'small'" variant="secondary"
                   [labelText]="'Cancel'" (clickEvent)="goToPage('home')"
                   iconName="" colorMode="on-light"></c11n-button>
    </div>
  </div>
</div>
