import { Injectable } from '@angular/core';

function _window(): any {
    return window;
}

@Injectable()
export class WindowRef {
    get nativeWindow(): any {
        return _window();
    }
    getWindowProperty(propertyName: string): any {
        return this.nativeWindow[propertyName];
    }

    setHref(href: string): void {
        this.nativeWindow.location.href = href;
    }
}
