import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ThirdpartyDocumentsComponent } from './components/thirdparty-documents/thirdparty-documents.component';

const routes: Routes = [{
  path: 'address',
  loadChildren: () =>
    import('./components/add-address-home/add-address-home.module').then((m) => m.AddAddressHomeModule),
}, {
  path: 'manage-address',
  loadChildren: () =>
    import('./components/delete-address/delete-address.module').then((m) => m.DeleteAddressModule),
}, {
  path: '',
  pathMatch: 'full',
  component: ThirdpartyDocumentsComponent,
  data: { pageId: 'duplicate-statements' },
}, {
  path: '**',
  redirectTo: '',
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
