import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ThirdPartyDocumentsResponse } from '@app/models/thirdparty-documents.model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { USStates, CountriesList } from "@app/state/models/add-address.model";

const httpOptions = {
  withCredentials: true,
};

@Injectable({
  providedIn: 'root'
})

export class ThirdPartyDocumentsService {
  constructor(
    private readonly http: HttpClient
  ) { }

  getThirdPartyAddressList(): Observable<ThirdPartyDocumentsResponse> {
    const endpoint = `${environment.THIRDPARTY_DOCUMENTS.BASE_URL}${environment.THIRDPARTY_DOCUMENTS.CONSUMER_URL}`;
    return this.http.get<ThirdPartyDocumentsResponse>(endpoint, httpOptions);
  }

  getAccounts(): Observable<USStates[]> {
    const endpoint = `${environment.THIRDPARTY_DOCUMENTS.BASE_URL}${environment.THIRDPARTY_DOCUMENTS.ACCOUNTS}`;
    return this.http.get<any[]>(endpoint, httpOptions);
  }

  getStates(): Observable<USStates[]> {
    const endpoint = `${environment.THIRDPARTY_DOCUMENTS.BASE_URL}${environment.THIRDPARTY_DOCUMENTS.STATES}`;
    return this.http.get<USStates[]>(endpoint, httpOptions);
  }

  getCountires(): Observable<CountriesList[]> {
    const endpoint = `${environment.THIRDPARTY_DOCUMENTS.BASE_URL}${environment.THIRDPARTY_DOCUMENTS.COUNTRIES}`;
    return this.http.get<CountriesList[]>(endpoint, httpOptions);
  }

  addAddress(payload): any {
    const endpoint = `${environment.THIRDPARTY_DOCUMENTS.BASE_URL}${environment.THIRDPARTY_DOCUMENTS.ADD_ADDRESS}`;
    // console.log(endpoint)
    return this.http.post<any>(endpoint, payload, httpOptions);
  }

  manageAddress(payload): any {
    const endpoint = `${environment.THIRDPARTY_DOCUMENTS.BASE_URL}${environment.THIRDPARTY_DOCUMENTS.MANAGE_ADDRESS}`;
    // console.log(endpoint)
    return this.http.put<any>(endpoint, payload, httpOptions);
  }
}
