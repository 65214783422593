import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { ThirdPartyDocumentsService } from '@app/services/thirdparty-documents/thirdparty-documents.service';
import { EMPTY, catchError, map, mergeMap } from "rxjs";
import {AddAddressActionType, loadUSStatesSuccess, loadCountiresListSuccess} from "@app/state/actions/add-address.action";

@Injectable()
export class AddAddressEffects {
    getStates$ = createEffect(() =>this.actions$.pipe(
        ofType(AddAddressActionType.LOAD_US_STATES),
        mergeMap(() => this.tpds.getStates().pipe(
            map(data => (loadUSStatesSuccess({allUSStates:data}))),
            catchError(() => EMPTY)
        )))
    )
    getCountriesList$ = createEffect(() =>this.actions$.pipe(
        ofType(AddAddressActionType.LOAD_COUNTRIES_LIST),
        mergeMap(() => this.tpds.getCountires().pipe(
            map(data => (loadCountiresListSuccess({countriesList:data}))),
            catchError(() => EMPTY)
        )))
    )    
    constructor(
        private actions$: Actions,
        private tpds:ThirdPartyDocumentsService
    ){}
}