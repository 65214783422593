import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ThirdpartyDocCaasObject } from '../../models/caas.model';
import { firstValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CaasService {
  private caasData: any;
  constructor(private readonly http: HttpClient) { }

  getContent() {
    return firstValueFrom(this.http.get<ThirdpartyDocCaasObject>(`${environment.THIRDPARTY_DOCUMENTS.BASE_URL}${environment.THIRDPARTY_DOCUMENTS.CAAS_URL}`)).then((response) => {
      this.caasData = response;
    }).catch((err) => {
      console.log(err);
    });
  }

  getCaasData() {
    return this.caasData;
  }
}
