import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from "@angular/router";
import { select, Store } from '@ngrx/store';
import { AddressModel, StepIndicatorModel, USStatesModel, CountriesModel } from "@app/state/models/add-address.model";
import { Observable } from "rxjs";
import { updateAddress, updateCurrentStep } from "../../../state/actions/add-address.action";
import { selectStepIndicatorIndex, getUSStates, getCountries, selectAddAddress } from "../../../state/selectors/add-address.selector";
import { content } from "../../../models/constants";
@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss']
})
export class AddAddressComponent implements OnInit {
  currentStepIndicatorIndex$: Observable<number>;
  showUsAddress: boolean;
  selectedCountry;
  selectedAccounts;
  title;
  content;
  @ViewChild('addressForm') public addressform: NgForm;


  myZipMask = {
    mask: 'VALUE',
    lazy: false,
    blocks: {
      VALUE: {
        mask: '00000-0000'
      }
    }
  }
  usStateList$: Observable<USStatesModel[]>;
  countryList$: Observable<CountriesModel[]>;
  address$: Observable<AddressModel>;

  constructor(private _router: Router,
    private store: Store<StepIndicatorModel>) {
    this.usStateList$ = this.store.select(getUSStates);
    this.countryList$ = this.store.select(getCountries);
    this.address$ = this.store.pipe(select(selectAddAddress));
    // this.showUsAddress = true;
  }
  ngOnInit(): void {
    this.currentStepIndicatorIndex$ = this.store.pipe(select(selectStepIndicatorIndex));
    this.selectedCountry = ''
    this.selectedAccounts = {};
    this.content = content;
    this.title = content.ADD_ADDRESS;
    this.address$.subscribe((data) => {
        this.showUsAddress = !data.foreign;
        this.selectedAccounts = data.linkedAccounts;
        this.title = data.linkedAccounts?.length === 0 ? content.ADD_ADDRESS : content.EDIT_ADDRESS;
        this.selectedCountry = data.foreignCountryCode?.trim().toUpperCase() + "," + data.foreignCountryName?.trim().toUpperCase();
        setTimeout(() => {
          this.addressform.form.patchValue({
            firstname: data.name,
            address1: data.addressLine1,
            address2: data.addressLine2,
            city: data.city,
            state: data.stateCode,
            zipcode: data.zipCode + '' + data.zipCodePlusFour,
            country: data.foreignCountryCode?.trim().toUpperCase() + "," + data.foreignCountryName?.trim().toUpperCase(),
            linkedAccounts: data.linkedAccounts
          })
        });
      });
  }

  addUserAddress(addressForm: NgForm) {
    if (addressForm.valid) {
      let userAddress = addressForm.value;
      if (this.showUsAddress) {
        userAddress.zipCodePlusFour = addressForm.value.zipcode.substr(5);
        userAddress.zipcode = addressForm.value.zipcode.substr(0, 5);
      }
      // console.log(userAddress)
      this.store.dispatch(
        updateCurrentStep({ currentStepIndex: 2 })
      );
      this.store.dispatch(
        updateAddress({
          address: {
            // name: userAddress.firstname + ' ' + userAddress.lastname,
            name: userAddress.firstname,
            addressLine1: userAddress.address1,
            addressLine2: userAddress.address2,
            city: userAddress.city,
            stateCode: this.showUsAddress ? userAddress.state : '',
            zipCode: this.showUsAddress ? userAddress.zipcode : '',
            zipCodePlusFour: this.showUsAddress ? userAddress.zipCodePlusFour : '',
            foreign: !this.showUsAddress,
            foreignCountryCode: !this.showUsAddress ? addressForm.value?.country?.split(',')[0] : '',
            foreignCountryName: !this.showUsAddress ? addressForm.value?.country?.split(',')[1] : '',
            linkedAccounts: this.selectedAccounts
          }
        })
      );
      // this._router.navigate(['select-accounts']);
    }
  }

  goToPage(route: string) {
    // cancel button is clicked so empty store
    this.store.dispatch(
      updateAddress({
        address: {
          name: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          stateCode: '',
          foreign: false,
          foreignCountryCode: '',
          foreignCountryName: '',
          linkedAccounts: []
        }
      })
    );
    this._router.navigate([route]);
  }

  checkValid(code) {
    return code;
  }
}
