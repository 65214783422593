import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LinkModule } from '@vg-constellation/angular-15/link';
import { environment } from "../environments/environment";
import { WindowRef } from './services/window-ref/window-ref.service';
import { firstValueFrom } from 'rxjs';
import { IconModule } from '@vg-constellation/angular-15/icon';
import { NavOverflowModule } from '@vg-constellation/angular-15/nav-overflow';
import { SpinnerModule } from '@vg-constellation/angular-15/spinner';
import { StepIndicatorModule } from '@vg-constellation/angular-15/step-indicator';
import { CaasService } from './services/caas/caas.service';
import { ThirdpartyDocumentsComponent } from './components/thirdparty-documents/thirdparty-documents.component';
import { GatekeeperService } from './services/gatekeeper/gatekeeper.service';
import { ButtonModule } from '@vg-constellation/angular-15/button';
import { OptimizeYourAccountComponent } from './components/optimize-your-account/optimize-your-account.component';
import { CardComponent } from "@vg-constellation/angular-15/card";
import { NgOptimizedImage } from "@angular/common";
import otlAngularConfig from '@vanguard/otl-angular-wrapper';
import {
  OpenTelemetryInterceptorModule,
  OtelColExporterModule,
  W3CTraceContextPropagatorModule
} from '@jufab/opentelemetry-angular-interceptor';
import { AuthInterceptor } from './auth.interceptor';
import {BannerComponent} from "@vg-constellation/angular-15/banner";
import { LabelModule } from '@vg-constellation/angular-15/label';

import { HintErrorModule } from '@vg-constellation/angular-15/hint-error';
// import { AddAddressComponent } from '@app/components/add-address-home/add-address/add-address.component';
// import { SelectAccountsFundsComponent } from '@app/components/add-address-home/select-accounts-funds/select-accounts-funds.component';
// import { BrokerageTypeComponent } from '@app/components/add-address-home/brokerage-type/brokerage-type.component';
// import { ReviewAndSubmitComponent } from '@app/components/add-address-home/review-and-submit/review-and-submit.component';
// import { ConfirmationComponent } from '@app/components/add-address-home/confirmation/confirmation.component';
// import { StepIndicatorComponent } from './components/step-indicator/step-indicator.component';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AddAddressEffects } from '@app/effects/add-address.effect';
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
// import { AddAddressHomeComponent } from './components/add-address-home/add-address-home.component';
import {AddAddressHomeModule} from "@app/components/add-address-home/add-address-home.module";
@NgModule({
  declarations: [AppComponent, ThirdpartyDocumentsComponent, OptimizeYourAccountComponent],
  imports: [BrowserModule, AppRoutingModule,
    LinkModule, HttpClientModule, IconModule,
    NavOverflowModule, SpinnerModule, CardComponent,
    NgOptimizedImage, ButtonModule,StepIndicatorModule, NavOverflowModule,
    LabelModule, HintErrorModule, FormsModule, AddAddressHomeModule,
    OpenTelemetryInterceptorModule.forRoot(
      otlAngularConfig({
        // substitute the following placeholders with your own application specific values
        serviceName: 'TPQ',
        appPrefix: 'TPQ', // you may also want to get this from the environment object, if present
        sysLevel: environment.SYS_LEVEL// or whatever key this value is stored under
      })
    ),
    StoreModule.forRoot(),
    StoreDevtoolsModule.instrument({name: 'ThirdPartyDocuments', maxAge :25}),
    OtelColExporterModule,
    W3CTraceContextPropagatorModule, BannerComponent,
    EffectsModule.forRoot([AddAddressEffects])
  ],
  providers: [
    WindowRef,
    CaasService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory(http: HttpClient, meta: Meta, windowRef: WindowRef) {
        const $window: Window = windowRef.nativeWindow;
        return async () => {
          await firstValueFrom(http
            .get(`${environment.THIRDPARTY_DOCUMENTS.BASE_URL}/adobe`, { withCredentials: true }))
            .then((data: any) => {
              const spoid = data.sPOID;
              meta.updateTag({ name: 'DCSext.poid', content: data.poid });
              meta.updateTag({ name: 'DCSext.sPoid', content: spoid }); // Used for Floodlight
              meta.updateTag({ name: 'WT.seg_6', content: data.crewFlag });
              // $window.spoid = spoid; // Used for Medallia
              $window.document.body.hidden = false;
            })
            .catch(() => {
              $window.document.location.href = environment.LOGON_URL;
              // Ensure that app initialization is aborted since we're going to the (external) logon page.
              return Promise.reject();
            });
        };
      },
      deps: [HttpClient, Meta, WindowRef],
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (caasService: CaasService) =>
        () => caasService.getContent(),
      deps: [CaasService],
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory(gatekeeperService: GatekeeperService, windowRef: WindowRef) {
        const $window: Window = windowRef.nativeWindow;
        return (): Promise<any> => {
          return new Promise(async (resolve) => {
            await firstValueFrom(
              gatekeeperService.isFeatureSwitchedOn(environment.GATEKEEPER_FEATURE_ID))
              .then((response: boolean) => {
                if (response) {
                  // redirect to legacy if gatekeeper is set to true
                  $window.document.location.href = environment.LEGACY_URL + '?redirect=true';
                } else {
                  gatekeeperService.redirectToModernizedApp();
                }
                resolve(true)
              }).catch(() => {
                resolve(true);
              })
          });
        }
      },
      deps: [GatekeeperService, WindowRef],
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
