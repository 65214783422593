import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { updateConfirmationNumber, updateCurrentStep } from "../../../state/actions/add-address.action";
import { select, Store } from "@ngrx/store";
import { StepIndicatorModel, AddressModel } from "@app/state/models/add-address.model";
import { firstValueFrom, Observable } from "rxjs";
import { selectSendAccounts, selectStepIndicatorIndex, selectAddAddress } from "../../../state/selectors/add-address.selector";
import { Account } from "@app/models/thirdparty-documents.model";
import { ThirdPartyDocumentsService } from "../../../services/thirdparty-documents/thirdparty-documents.service";
import { ModalDialogComponent } from '@vg-constellation/angular-15/modal-dialog';

@Component({
  selector: 'app-review-and-submit',
  templateUrl: './review-and-submit.component.html',
  styleUrls: ['./review-and-submit.component.scss']
})
export class ReviewAndSubmitComponent implements OnInit {
  currentStepIndicatorIndex$: Observable<number>;
  private allAccounts$: Observable<Account[]>;
  address$: Observable<AddressModel>;
  isLoaderIndicate: boolean = false;
  accounts: any;
  address: any;
  linkedAccounts: any;
  allAccounts: any;
  errorMessage: string;
  @ViewChild('errorAddModal') errorModal: ModalDialogComponent;

  constructor(
    private _router: Router,
    private store: Store<StepIndicatorModel>,
    private readonly thirdPartyDocumentsService: ThirdPartyDocumentsService,
  ) { }

  ngOnInit(): void {
    // this.currentStepIndicatorIndex$ = this.store.select((state:any) => (state.stepIndicatorReducer.currentStepIndex));
    this.currentStepIndicatorIndex$ = this.store.pipe(select(selectStepIndicatorIndex));
    this.address$ = this.store.pipe(select(selectAddAddress))
    this.address$.pipe().subscribe((data) => {
      // console.log(data)
      this.address = data;
      this.linkedAccounts = data.linkedAccounts
      console.log(this.linkedAccounts)
    });
    this.allAccounts$ = this.store.pipe(select(selectSendAccounts))
    this.allAccounts$.pipe().subscribe((data: any) => {
      // console.log(data.accounts)
      this.allAccounts = JSON.parse(JSON.stringify(data.accounts));
      console.log(this.allAccounts)
      this.accounts = JSON.parse(JSON.stringify(data.accounts));
      this.accounts = this.accounts.filter(account => {
        if (account.productType.includes('BROKERAGE') && account.isChecked === true) {
          return account
        } else {
          account.positions = account.positions.filter(pos => {
            if (pos.isChecked === true) return pos
          })
          if (account.positions.length > 0) return account
        }
      })
      // console.log(this.accounts)
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  handleClick = (event: any) => {
    const expandedState = event.currentTarget.getAttribute('aria-expanded') === 'true';
    event.currentTarget.setAttribute('title', expandedState ? 'show more' : 'show less');
    event.currentTarget.setAttribute('aria-expanded', !expandedState);
    if (!expandedState) {
      event.currentTarget.parentElement.parentElement.classList.add('c11n-accordion--expanded');
    } else {
      event.currentTarget.parentElement.parentElement.classList.remove('c11n-accordion--expanded');
    }
  };

  async goToPage(event, route?: string) {
    let payload: any = {
      addressLine1: "",
      foreign: false,
      authorizedAccountFunds: [],
      name: "",
      addressLine3: "",
      addressLine4: ""
    };
    payload.name = this.address.name;
    payload.addressLine1 = this.address.addressLine1;
    payload.addressLine2 = this.address.addressLine2;
    payload.foreign = this.address.foreign;
    if (this.address.foreign) {
      payload.foreignCountryCode = this.address.foreignCountryCode;
      payload.foreignCountryName = this.address.foreignCountryName;
      payload.city = null;
      payload.stateCode = null;
      payload.zipCode = null;
      payload.zipCodePlusFour = null;
    } else {
      payload.city = this.address.city;
      payload.stateCode = this.address.stateCode;
      payload.zipCode = this.address.zipCode;
      payload.zipCodePlusFour = this.address.zipCodePlusFour
      payload.foreignCountryCode = null;
      payload.foreignCountryName = null;
    }
    if(this.linkedAccounts.length ===0){
      this.accounts.forEach(account => {
        if (account.productType.includes('BROKERAGE')) {
          payload.authorizedAccountFunds.push({
            "accountId": account.accountId,
            "optedFor": account.optedFor,
          })
        } else if (account.productType.includes('MUTUAL_FUND')) {
          account.positions.forEach(position => {
            payload.authorizedAccountFunds.push({
              "vanguardFundId": position.vanguardFundId,
              "accountId": account.accountId,
              "optedFor": position.optedFor,
              "vastAccountNumber": position.vastAccountNumber
            })
          })
        }
      })
    } else {
      this.linkedAccounts.map((linkedAccount) => {
        this.allAccounts.forEach(account => {
          if (account.productType.includes('BROKERAGE')) {
            if (account.accountId === linkedAccount.accountId) {
              if (account['isChecked']) {
                payload.authorizedAccountFunds.push({
                  "agreementId": linkedAccount.agreementId,
                  // "sagId": linkedAccount.sagId,
                  "accountId": account.accountId,
                  // "accountType": account.productType,
                  "optedFor": account.optedFor,
                  "action": "UPDATE_ACCOUNT_REFERENCE",
                  "sequenceNumber": linkedAccount.sequenceNumber
                })
              } else {
                payload.authorizedAccountFunds.push({
                  "agreementId": linkedAccount.agreementId,
                  // "sagId": linkedAccount.sagId,
                  "accountId": account.accountId,
                  // "accountType": account.productType,
                  "optedFor": linkedAccount.option || null,
                  "action": "REMOVE_ACCOUNT_REFERENCE",
                  "sequenceNumber": linkedAccount.sequenceNumber
                })
              }
              account['isLooped'] = true;
            }
          } else if(account.productType.includes('MUTUAL_FUND')){
            account.positions.forEach(position => {
              if ((position.vanguardFundId == linkedAccount.vanguardFundId) && (account.accountId == linkedAccount.accountId) && (position.vastAccountNumber == linkedAccount.vastAccountNumber)) {
                if(position['isChecked']){
                  payload.authorizedAccountFunds.push({
                    "agreementId": linkedAccount.agreementId,
                    "vanguardFundId": position.vanguardFundId,
                    "accountId": account.accountId,
                    // "accountType": account.productType,
                    "optedFor": position.optedFor,
                    "action": "UPDATE_ACCOUNT_REFERENCE",
                    "sequenceNumber": linkedAccount.sequenceNumber,
                    "vastAccountNumber": linkedAccount.vastAccountNumber
                  })
                } else {
                  payload.authorizedAccountFunds.push(
                    {
                      "agreementId": linkedAccount.agreementId,
                      "vanguardFundId": position.vanguardFundId,
                      "accountId": account.accountId,
                      // "accountType": account.productType,
                      "optedFor": linkedAccount.option,
                      "action": "REMOVE_ACCOUNT_REFERENCE",
                      "sequenceNumber": linkedAccount.sequenceNumber,
                      "vastAccountNumber": linkedAccount.vastAccountNumber
                    }
                  )
                }
                position['isLooped'] = true;
              }

            })
          }
        })
      })
      this.allAccounts.forEach(account => {
        if (account.productType.includes('BROKERAGE')) {
          if (account['isChecked'] && !account['isLooped']) {
            payload.authorizedAccountFunds.push({
              "agreementId": "",
              // "sagId": "",
              "accountId": account.accountId,
              // "accountType": account.productType,
              "optedFor": account.optedFor,
              "action": ""
            })
          }
        }
        else if(account.productType.includes('MUTUAL_FUND')){
          account.positions.forEach(position => {
            if(position['isChecked']  && !position['isLooped']){
              payload.authorizedAccountFunds.push({
                "agreementId": "",
                // "sagId": "",
                "vanguardFundId": position.vanguardFundId,
                "accountId": account.accountId,
                // "accountType": account.productType,
                "optedFor": position.optedFor,
                "action": "",
                "vastAccountNumber": position.vastAccountNumber
              })
            }
          })
        }
      })
      // console.log(payload.authorizedAccountFunds)

    }
    // console.log(payload)
    try {
      this.isLoaderIndicate = true;
      let res:any;
     if(this.linkedAccounts.length ===0 ) {
      this.errorMessage = 'Cannot add this address, Please try again later.';
        res = await firstValueFrom(this.thirdPartyDocumentsService.addAddress(payload))
     } else{
      this.errorMessage = 'Cannot edit this address, Please try again later.'
       res = await firstValueFrom(this.thirdPartyDocumentsService.manageAddress(payload))
     }
      this.isLoaderIndicate = false;
      // console.log(res)
      if (!res.error && !res?.errorCode) {
        this.store.dispatch(updateConfirmationNumber({ confirmationNumber: res.confirmationNumber }))
        this.store.dispatch(
          updateCurrentStep({ currentStepIndex: 5 })
        );
        if (route)
          this._router.navigate([route]);
      }
      else {
        this.errorModal.openModalDialog(event);
      }
    } catch (error) {
      this.isLoaderIndicate = false;
      this.errorModal.openModalDialog(event);
      // console.log(error);
    }
  }
}
