<div class="container extra-space">
  <div class="step-indicator-container">
    <app-step-indicator [currentStep]="(currentStepIndicatorIndex$ | async) || 2"></app-step-indicator>
  </div>
  <div>
    <p class="header-label">Select accounts associated with this duplicate statement</p>
  </div>
  <div class="title-container">
    <p class="title-text">Select the accounts or holdings that will provide statements to the recipient.</p>
  </div>

  <div class="new-address-container" #fundErrorBannner>
    <p class="new-address-title">New address</p>
    <div class="new-address">
      <p>{{(address$ | async)?.name}}</p>
      <p>{{(address$ | async)?.addressLine1}}<span *ngIf="(address$|async)?.addressLine2">,</span> {{(address$ |
        async)?.addressLine2}}</p>
      <p *ngIf="(address$|async)?.city">{{(address$ | async)?.city}}, {{(address$ | async)?.stateCode}} {{(address$ |
        async)?.zipCode}}<span *ngIf="(address$|async)?.zipCodePlusFour">-</span>{{(address$ | async)?.zipCodePlusFour}}
      </p>
      <p *ngIf="(address$|async)?.foreign">{{(address$ | async)?.foreignCountryCode}}</p>
    </div>
  </div>

  <div class="c11n-banner c11n-banner--small c11n-banner--error banner-space" *ngIf="showSelectAccontErrorBanner">
    <div class="c11n-banner__leading-icon">
      <svg class="c11n-error-small" role="img" aria-hidden="false" focusable="true">
        <title>error</title>
        <use xlink:href="#error-small"></use>
      </svg>
    </div>
    <div class="c11n-banner__main">
      <div class="c11n-banner__content">
        <div class="c11n-banner__body">
          <div class="c11n-text-md--crop"><b>Select an account and click Continue.</b></div>
        </div>
      </div>
    </div>
  </div>

  <div class="select-account-container">
    <p class="select-acc-label">Accounts available for selection</p>
    <div class="accordion-container">
      <div class="row c11n-space-stack-5x">
        <div class="col-12 col-lg-12 col-md-12">
          <ng-container *ngFor="let acc of accounts; index as i">
            <ng-container *ngTemplateOutlet="
              accordion;
              context: {
                size: 'large',
                weight: 'heavy',
                labelText: acc.accountName,
                iconPosition: 'leading',
                summaryText: '',
                isExpanded: true,
                account: acc,
                mainIndex: i
              }
            "></ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="row btn-container">
      <div class="col-4 col-lg-2 col-xl-2 ps-0 mb-3 mb-lg-0 btn-mobile">
        <c11n-button buttonType="button" [size]="'small'" variant="primary" [labelText]="'Continue'"
          (clickEvent)="goToPage('')" iconName="" colorMode="on-light"></c11n-button>
      </div>
      <div class="col-4 col-lg-2 col-xl-2 ps-0 btn-mobile">
        <c11n-button buttonType="button" [size]="'small'" variant="secondary" [labelText]="'Cancel'"
          iconName="" (clickEvent)="goToPage('home')" colorMode="on-light"></c11n-button>
      </div>
    </div>
  </div>


  <ng-template let-labelText="labelText" let-summaryText="summaryText" let-weight="weight" let-size="size"
    let-isExpanded="isExpanded" let-iconPosition="iconPosition" let-account="account" let-mainIndex="mainIndex"
    #accordion>
    <div class="c11n-accordion c11n-accordion--{{ size }} c11n-accordion--{{
      weight
    }}-weight c11n-accordion--{{ iconPosition }} {{
      isExpanded ? 'c11n-accordion--expanded' : ''
    }} {{ summaryText !== '' ? 'c11n-accordion--summary' : '' }}">

      <h3>
        <div style="position: absolute; top: 29px;">
          <c11n-checkbox [labelText]="''" [hideLabel]="true" [variant]="'default'" [descriptiveLabel]="''"
            [labelWeight]="'normal'" (change)="headerCheckBox(account, $event)"
            [isDisabled]="account.noOfLinkedAddresses>=9">
            <input c11nCheckboxInput type="checkbox" name="fund-name" [value]="account.accountId"
              [checked]="account.isChecked" />
          </c11n-checkbox>
        </div>
        <button type="button" class="c11n-accordion__trigger" [attr.aria-expanded]="isExpanded" title="show less"
          (click)="handleClick($event)">
          <span class="c11n-accordion__trigger-items" style="margin-left: 40px;">
            <span class="c11n-accordion__heading-group">
              <span class="c11n-accordion__heading">
                <span>
                  <span [ngClass]="account.noOfLinkedAddresses>=9 ? 'disableRow': ''">{{ labelText }}</span>
                </span>
              </span>
              <span *ngIf="summaryText !== ''" class="c11n-accordion__content">{{ summaryText }}</span>
            </span>
            <ng-container *ngTemplateOutlet="icon"></ng-container>
          </span>
        </button>
      </h3>
      <div class="c11n-accordion__body mt-2">
        <div class="c11n-accordion__body-content">
          <ng-container
            *ngTemplateOutlet="account.brokerageAccountNumber ? brokarageTableBody : mutualFundBody; context: {accountData: account}"></ng-container>
        </div>
      </div>
    </div>
    <ng-template #icon>
      <span *ngIf="size === 'medium'" class="c11n-accordion__icon">
        <svg title="toggle content" class="c11n-caret-down-small">
          <use xlink:href="#caret-down-small"></use>
        </svg>
      </span>
      <span *ngIf="size === 'large'" class="c11n-accordion__icon-container">
        <span class="c11n-accordion__icon">
          <svg title="toggle content" class="c11n-accordion-closed-caret-small">
            <use xlink:href="#accordion-closed-caret-small"></use>
          </svg>
        </span>
      </span>
    </ng-template>
  </ng-template>

  <ng-template #brokarageTableBody let-accountData="accountData">
    <div class="c11n-accordion-story-content">
      <div class="c11n-table__symbols">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 14" width="8" height="14">
          <symbol id="c11n-table-sort-arrow" viewBox="0 0 8 14">
            <path
              d="m.1455522 5.8535523 3.5008945 3.5008941c.195262.1952629.5118446.1952629.7071066 0l3.5008945-3.5008941c.314982-.3149824.091899-.8535533-.3535533-.8535533H.4991056c-.4454524 0-.6685358.5385709-.3535534.8535533z">
            </path>
          </symbol>
        </svg>
      </div>
      <table id="brokarage-sortableTable" class="c11n-table c11n-table--small" aria-label="select account table">
        <thead>
          <tr class="c11n-table__tr">
            <th id="brokarage-col-1-header-2" scope="col" class="c11n-table__thead-th  "
              [attr.aria-sort]="accountData.sortingState"
              [ngClass]="[accountData.sortingState, accountData.activeSort === 'ticker' ? 'c11n-table__thead-th--active-sort' : '']">
              <button type="button" class="c11n-link c11n-link--secondary c11n-link--icon c11n-link--normal"
                title="Sortable" aria-label="sorted descending" (click)="sortColumn(accountData, 'ticker')">
                <span class="c11n-link__content">
                  <span class="c11n-link__text">Symbol</span>
                  <svg viewBox="0 0 8 14" aria-hidden="true">
                    <use xlink:href="#c11n-table-sort-arrow"></use>
                    <use xlink:href="#c11n-table-sort-arrow"></use>
                  </svg>
                </span>
              </button>
            </th>

            <th id="brokarage-col-2-header-2" scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-left"
              [ngClass]="[accountData.sortingState, accountData.activeSort === 'securityName' ? 'c11n-table__thead-th--active-sort' : '']">
              <button type="button" class="c11n-link c11n-link--secondary c11n-link--icon c11n-link--normal"
                title="Sortable" aria-label="sortable" (click)="sortColumn(accountData, 'securityName')">
                <span class="c11n-link__content">
                  <span class="c11n-link__text">Name</span>
                  <svg viewBox="0 0 8 14" aria-hidden="true">
                    <use xlink:href="#c11n-table-sort-arrow"></use>
                    <use xlink:href="#c11n-table-sort-arrow"></use>
                  </svg>
                </span>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let brokarageData of accountData.positions">
            <tr class="c11n-table__tr" *ngIf="brokarageData.ticker">
              <td scope="row" class="c11n-table__td c11n-table__tbody-th c11n-table__td--sortable"><span
                  class="symbol-text">{{brokarageData?.ticker}}</span></td>
              <td class="c11n-table__td c11n-table__td--align-left c11n-table__td--sortable account-name">
                {{brokarageData?.securityName}}</td>
            </tr>
          </ng-container>

        </tbody>
      </table>
    </div>
  </ng-template>

  <ng-template #mutualFundBody let-accountData="accountData">
    <div class="c11n-accordion-story-content">
      <div class="c11n-table__symbols">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 14" width="8" height="14">
          <symbol id="c11n-table-sort-arrow" viewBox="0 0 8 14">
            <path
              d="m.1455522 5.8535523 3.5008945 3.5008941c.195262.1952629.5118446.1952629.7071066 0l3.5008945-3.5008941c.314982-.3149824.091899-.8535533-.3535533-.8535533H.4991056c-.4454524 0-.6685358.5385709-.3535534.8535533z">
            </path>
          </symbol>
        </svg>
      </div>
      <table id="mutualfund-sortableTable" class="c11n-table c11n-table--small" aria-label="select account table">
        <thead>
          <tr class="c11n-table__tr">
            <th id="mutual-col-1-header-2" scope="col" class="c11n-table__thead-th  c11n-table__thead-th--active-sort">
            </th>
            <th id="mutualfund-col-1-header-2" scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-left"
              [ngClass]="[accountData.sortingState, accountData.activeSort === 'vanguardFundId' ? 'c11n-table__thead-th--active-sort' : '']">
              <button type="button" class="c11n-link c11n-link--secondary c11n-link--icon c11n-link--normal"
                title="Sortable" aria-label="sorted descending" (click)="sortColumn(accountData, 'vanguardFundId')">
                <span class="c11n-link__content">
                  <span class="c11n-link__text">Fund & Account</span>
                  <svg viewBox="0 0 8 14" aria-hidden="true">
                    <use xlink:href="#c11n-table-sort-arrow"></use>
                    <use xlink:href="#c11n-table-sort-arrow"></use>
                  </svg>
                </span>
              </button>
            </th>

            <th id="mutualfund-col-2-header-2" scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-left"
              [ngClass]="[accountData.sortingState, accountData.activeSort === 'securityName' ? 'c11n-table__thead-th--active-sort' : '']">
              <button type="button" class="c11n-link c11n-link--secondary c11n-link--icon c11n-link--normal"
                title="Sortable" aria-label="sortable" (click)="sortColumn(accountData, 'securityName')">
                <span class="c11n-link__content">
                  <span class="c11n-link__text">Name</span>
                  <svg viewBox="0 0 8 14" aria-hidden="true">
                    <use xlink:href="#c11n-table-sort-arrow"></use>
                    <use xlink:href="#c11n-table-sort-arrow"></use>
                  </svg>
                </span>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="c11n-table__tr" *ngFor="let mutualFund of accountData.positions">
            <td class="c11n-table__tbody-th c11n-table__tbody-th--align-center">
              <div class="c11n-checkbox">
                <c11n-checkbox [labelText]="''" [hideLabel]="true" [variant]="'default'" [descriptiveLabel]="''"
                  [labelWeight]="'normal'" (change)="mutualFundCheckbox(mutualFund, accountData, $event)"
                  [isDisabled]="mutualFund.noOfLinkedAddresses >9">
                  <input c11nCheckboxInput type="checkbox" name="mutual-fund"
                    [value]="mutualFund.vastAccountNumber +'-'+ mutualFund.vanguardFundId"
                    [checked]="mutualFund.isChecked" />
                </c11n-checkbox>
              </div>
            </td>
            <td scope="row"
              class="c11n-table__td  c11n-table__td--align-left c11n-table__tbody-th c11n-table__td--sortable"><span
                [ngClass]="mutualFund.noOfLinkedAddresses>9 ? 'disableRow': 'symbol-text'">{{mutualFund?.vanguardFundId}}-{{mutualFund?.vastAccountNumber}}</span>
            </td>
            <td class="c11n-table__td c11n-table__td--align-left c11n-table__td--sortable"
              [ngClass]="mutualFund.noOfLinkedAddresses>9 ? 'disableRow': 'account-name'">
              {{mutualFund?.securityName}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-template>
</div>