import { Component, Input, OnInit } from '@angular/core';
import { ThirdpartyDocumentsCaasContent } from '../../models/caas.model';
import {
  CardImageAspectRatio,
} from "@vg-constellation/angular-15/card";
@Component({
  selector: 'app-optimize-your-account',
  templateUrl: './optimize-your-account.component.html',
  styleUrls: ['./optimize-your-account.component.scss']
})
export class OptimizeYourAccountComponent implements OnInit {
  density: any = 'small';
  hasImage = true;
  hasIcon = false;
  imagePadding = true;
  imageAspectRatio: CardImageAspectRatio = '3-2';

  @Input() thirdpartyDocumentsCaasContent: ThirdpartyDocumentsCaasContent;
  optimizeTilesContent;


  ngOnInit() {
    this.getoptimizeTilesContent();
  }

  getoptimizeTilesContent() {
    this.optimizeTilesContent = Object.keys(this.thirdpartyDocumentsCaasContent?.optimizeTiles).sort().map(key =>
    ({
      'linkLabel': this.thirdpartyDocumentsCaasContent.optimizeTiles[key]?.linkLabel || '',
      'linkPath': this.thirdpartyDocumentsCaasContent.optimizeTiles[key]?.linkPath || '',
      'tileText': this.thirdpartyDocumentsCaasContent.optimizeTiles[key]?.tileText || '',
      'tileTitle': this.thirdpartyDocumentsCaasContent.optimizeTiles[key]?.tileTitle || ''
    })
    );
  }
}
