import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { AddressModel, StepIndicatorModel } from "@app/state/models/add-address.model";
import { sendAccounts, updateCurrentStep } from "../../../state/actions/add-address.action";
import {
  selectAddAddress,
  selectSendAccounts,
  selectStepIndicatorIndex
} from "../../../state/selectors/add-address.selector";
import { Observable } from "rxjs";
import { Account } from "@app/models/thirdparty-documents.model";

@Component({
  selector: 'app-select-accounts-funds',
  templateUrl: './select-accounts-funds.component.html',
  styleUrls: ['./select-accounts-funds.component.scss']
})
export class SelectAccountsFundsComponent implements OnInit {
  currentStepIndicatorIndex$: Observable<number>;
  address$: Observable<AddressModel>;
  allAccounts$: Observable<Account[]>
  accounts;
  linkedAccounts;
  showSelectAccontErrorBanner: boolean = false;
  @ViewChild('fundErrorBannner') fundErrorBannner: ElementRef;

  constructor(
    private _router: Router,
    private store: Store<StepIndicatorModel>
  ) { }
  ngOnInit(): void {
    this.currentStepIndicatorIndex$ = this.store.pipe(select(selectStepIndicatorIndex));
    this.address$ = this.store.pipe(select(selectAddAddress))
    this.address$.pipe().subscribe((data) => {
      this.linkedAccounts = data.linkedAccounts;
      // console.log('this.linkedAccounts', this.linkedAccounts)
    });
    this.allAccounts$ = this.store.pipe(select(selectSendAccounts))
    this.allAccounts$.pipe().subscribe((data: any) => {
      // console.log(data.accounts)
      this.accounts = JSON.parse(JSON.stringify(data.accounts));
      this.accounts.map((account) => {
        let positionCounter = 0;
        this.linkedAccounts.map((linkedAccount) => {
          if (account.productType.includes('MUTUAL_FUND') && linkedAccount.accountId === account.accountId) {
            account.positions.map((position) => {
              if (position.vanguardFundId == linkedAccount.vanguardFundId && position.vastAccountNumber == linkedAccount.vastAccountNumber) {
                position['isChecked'] = true;
                positionCounter++;
              }
            });
            if (positionCounter === account.positions.length) {
              account['isChecked'] = true;
            }
          }
          if (account.productType.includes('BROKERAGE') && account.accountId === linkedAccount.accountId) {
            account['isChecked'] = true;
          }
        })
      });
    });
    // console.log('accounts ', this.accounts)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  goToPage(route?: string) {
    if (route) {
      this.store.dispatch(
        sendAccounts({
          accounts: {
            accounts: [{
              accountId: "",
              accountName: "",
              brokerageAccountNumber: "",
              productType: "",
              noOfLinkedAddresses: 0,
              positions: [
                {
                  fundName: "",
                  symbol: "",
                  vanguardFundId: "",
                  vastAccountNumber: "",
                  noOfLinkedAddresses: 0,
                }
              ]
            }]
          }
        })
      );
      this._router.navigate([route]);
    } else {
      let counter: number = 0;
      this.accounts.forEach(element => {
        if (element.productType.includes('BROKERAGE') && element.isChecked) {
          counter++;
        }
        if (element.productType.includes('MUTUAL_FUND')) {
          element.positions.forEach(el => {
            if (el.isChecked) {
              counter++;
            }
          });
        }
      });
      if (!counter) {
        this.showSelectAccontErrorBanner = true;
        this.fundErrorBannner.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
      else {
        this.store.dispatch(
          updateCurrentStep({ currentStepIndex: 3 })
        );
        this.store.dispatch(
          sendAccounts({ accounts: { accounts: this.accounts } })
        );
      }
    }
  }

  handleClick = (event: any) => {
    const expandedState = event.currentTarget.getAttribute('aria-expanded') === 'true';
    event.currentTarget.setAttribute('title', expandedState ? 'show more' : 'show less');
    event.currentTarget.setAttribute('aria-expanded', !expandedState);
    if (!expandedState) {
      event.currentTarget.parentElement.parentElement.classList.add('c11n-accordion--expanded');
    } else {
      event.currentTarget.parentElement.parentElement.classList.remove('c11n-accordion--expanded');
    }
  };

  headerCheckBox(accountData, selection) {
    this.showSelectAccontErrorBanner = false;
    if (!accountData.brokerageAccountNumber) {
      accountData.positions.forEach(el => {
        el.isChecked = selection.target.checked;
      })
    }
    else {
      accountData.isChecked = selection.target.checked;
    }
  }

  mutualFundCheckbox(mutualFundData, mainAccountData, selection) {
    this.showSelectAccontErrorBanner = false;
    mutualFundData.isChecked = selection.target.checked;
    mainAccountData.isChecked = mainAccountData.positions.length === mainAccountData.positions.filter(el => el.isChecked).length;
  }

  getAccountData() {
    this.accounts.forEach(el => {
      if (!el.brokerageAccountNumber) {
        el.positions.forEach(res => {
          res.isChecked = false;
        })
      }
    })
  }

  sortColumn(accountData, columnSelection) {
    accountData.activeSort = columnSelection;
    if (accountData.sortingState === 'descending') {
      this.sortAscending(accountData.positions, columnSelection);
      accountData.sortingState = 'ascending'
    }
    else {
      this.sortDecending(accountData.positions, columnSelection);
      accountData.sortingState = 'descending';
    }
    // console.log(accountData);
  }

  sortDecending(arr, column) {
    return arr.sort((a, b) => b[column].localeCompare(a[column]));
  }

  sortAscending(arr, column) {
    return arr.sort((a, b) => a[column].localeCompare(b[column]));
  }
}
