import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AddAddressMasterComponent
} from "@app/components/add-address-home/add-address-master/add-address-master.component";

const routes: Routes = [{ path: '', component: AddAddressMasterComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AddAddressHomeRoutingModule {}
