import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
  AccountsModel,
  AddAddressModel,
  StepIndicatorModel,
  USStates,
  CountriesList,
  ConfirmationModel
} from '../models/add-address.model';
export const getStepIndicatorState = createFeatureSelector<StepIndicatorModel>('currentStepIndex')
export const addAddressState = createFeatureSelector<AddAddressModel>('currentStepIndex')
export const sendAccountsState = createFeatureSelector<AccountsModel>('currentStepIndex')
export const getAllUSStates = createFeatureSelector<USStates>('allUSStates')
export const getCountriesList = createFeatureSelector<CountriesList>('allCountiresList')
export const getConfirmationNumber = createFeatureSelector<ConfirmationModel>('confirmationNumber')
export const selectStepIndicatorIndex = createSelector(
  getStepIndicatorState,
  (state: StepIndicatorModel) => {
    return state.currentStepIndex;
  },
);
export const selectConfirmationNumber = createSelector(
  getConfirmationNumber,
  (state: ConfirmationModel) => {
    return state.confirmationNumber;
  },
);
export const selectAddAddress = createSelector(
  addAddressState,
  (state: AddAddressModel) => {
  //  console.log(state)
    return state.address

  },
);
export const selectSendAccounts = createSelector(
  sendAccountsState,
  (state:AccountsModel) => {
    // console.log(state)
    return state.accounts

  },
);

export const getUSStates = createSelector(getAllUSStates, state => state.allStates);
export const getCountries = createSelector(getCountriesList, state => state.countriesList);

