import { OnInit, Component } from '@angular/core';
import { ThirdPartyDocumentsService } from '../../services/thirdparty-documents/thirdparty-documents.service';
import { ThirdpartyDocumentsCaasContent } from '../../models/caas.model';
import { CaasService } from '../../services/caas/caas.service';
import { firstValueFrom } from 'rxjs';
import { ThirdPartyDocumentsResponse, ThirdPartyDocsObject } from '@app/models/thirdparty-documents.model';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from "@ngrx/store";
import { StepIndicatorModel } from "@app/state/models/add-address.model";
import { sendAccounts, updateCurrentStep, updateAddress } from "../../state/actions/add-address.action";
@Component({
  selector: 'app-thirdparty-documents',
  templateUrl: './thirdparty-documents.component.html',
  styleUrls: ['./thirdparty-documents.component.scss']
})

export class ThirdpartyDocumentsComponent implements OnInit {
  urlSafe: SafeResourceUrl;
  showActionLabel = true;
  isLoaderIndicate = true;
  isSmallButton = false;
  hasCloseControl = false;
  hasDetails = false;
  ftnMessage = "";
  actionMenuData = {
    "groups": [
      {
        "items": [
          {
            "labelText": "Change address and its accounts",
            "routerLink": "add-address-home"
          },
          {
            "labelText": "Delete this address",
            "href": ""
          },
          {
            "labelText": "View accounts that use this address",
            "href": ""
          }
        ]
      }
    ]
  };
  navigationContent;
  faqContent;
  actionButtonsContent;
  thirdpartyDocumentsCaasContent: ThirdpartyDocumentsCaasContent;
  thirdPartyDocAddressList: ThirdPartyDocsObject[] = [];
  thirdPartySpecialPayeeAddressList: ThirdPartyDocsObject[] = [];
  is529BtnEnabled = false;
  contentBody: string;
  contentBodyForCaasError: string;
  shouldShowErrorBanner = false;
  shouldShowErrorBannerForCaas = false;
  errorData: { [p: string]: string };
  defaultErrorCode = "DEFAULT_ERROR";

  constructor(
    private readonly caasService: CaasService,
    private readonly thirdPartyDocumentsService: ThirdPartyDocumentsService,
    private readonly sanitizer: DomSanitizer,
    private _router: Router,
    private store: Store<StepIndicatorModel>
  ) { }

  async ngOnInit() {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(environment.LEGACY_URL);
    if (window.innerWidth < 768) {
      this.showActionLabel = false;
      this.isSmallButton = true;
    }
    await this.getCaasData();
    await this.getThirdPartyDocumentsAddressList();
  }

  async getCaasData() {
    const response = this.caasService.getCaasData();
    if (response) {
      // await this.getThirdPartyDocumentsAddressList();
      this.thirdpartyDocumentsCaasContent = response?.Data['third-party-duplicates']?.content
        ? response.Data['third-party-duplicates'].content : {
          browserTitle: "Interested party duplicate statements & confirmations",
          pageTitle: "Interested party duplicate statements & confirmations",
          navigation: {
            "option_02": {
              "optionLabel": "Interested party duplicate statements and confirmations"
            },
            "option_01": {
              "optionLabel": "Profile & account settings",
              "optionUrl": "https://satpersonal1.vanguard.com/mnt-profile-account-settings/"
            }
          },
          optimizeLabel: "Optimize your account",
          optimizeTiles: {
            "tile_01": {
              "linkLabel": "Enroll in e-delivery",
              "tileText": "<p>Take the worry ...</p>\n",
              "tileTitle": "Enroll in e-delivery",
              "linkPath": "https://satpersonal1.vanguard.com/mp1-delivery-settings/welcome"
            },
            "tile_02": {
              "linkLabel": "View statements",
              "tileText": "<p>View and download your statements or use our custom report tool to get insight you need.</p>\n",
              "tileTitle": "View statements",
              "linkPath": "https://statements.webt.vanguard.com/"
            },
            "tile_03": {
              "linkLabel": "View tax center",
              "tileText": "<p>View and download tax forms in our tax center. Get access to personilaized tax form schedule</p>\n",
              "tileTitle": "Visit tax center",
              "linkPath": "https://satpersonal1.vanguard.com/ffa-ffa-tax-ui/tax-center"
            }
          },
          faqContent: {
            "3": {
              "optionKey": "3",
              "optionValue": "Receiving duplicate statement has high impact on the environment and results in added cost to our investors. " +
                "It is advised to only use this option in limited circumstances. "
            },
            "faq_01": {
              "optionKey": "1",
              "optionValue": "The third party duplicate statement mail option allows a client to send information about a given Vanguard Brokerage Account to another address," +
                " in addition to the address of record."
            }
          },
          rowActions: {
            "rowaction_03": {
              "optionLabel": "View accounts that use this address",
              "optionUrl": "https://satpersonal.vanguard.com:53443/us/AddrVAController?tasktype=5&addrtype=3&addrid=2"
            },
            "rowaction_01": {
              "optionLabel": "Change address and its accounts",
              "optionUrl": "https://satpersonal.vanguard.com:53443/us/AddrMAController?tasktype=1&addrtype=3&addrid=1"
            },
            "rowaction_02": {
              "optionLabel": "Delete this address",
              "optionUrl": "https://satpersonal.vanguard.com:53443/us/AddrDAController?tasktype=3&addrtype=3&addrid=1"
            }
          },
          actionButtons: {
            "button_01": {
              "optionLabel": "Add a duplicate statement address",
              "optionUrl": "https://satpersonal.vanguard.com:53443/us/faces/JSP/MyPortfolio/MyServices/AddrAAAddContent.jsp?addrtype=3&RFD="
            },
            "button_02": {
              "optionLabel": "Add a duplicate vanguard 529 address"
            }
          },
          faqTitle: "What do I need to know?",
          payeeTitle: "Special payee address",
          errorObject: {
            "error-object-01": {
              "optionKey": "ERROR_001",
              "optionValue": "The information is currently unavailable. Please try again later."
            },
            "error-object-02": {
              "optionKey": "ERROR_002",
              "optionValue": "Currently, we may not be showing all the duplicate addresses. Please try again later."
            }
          }
        }

      // const tempActionArr = Object.keys(this.thirdpartyDocumentsCaasContent.rowActions).sort().map(key =>
      // ({
      //   'labelText': this.thirdpartyDocumentsCaasContent.rowActions[key].optionLabel,
      //   'href': this.thirdpartyDocumentsCaasContent.rowActions[key].optionUrl || ''
      // })
      // );
      // this.actionMenuData.groups[0].items = tempActionArr;

      this.navigationContent = Object.keys(this.thirdpartyDocumentsCaasContent.navigation).sort().map(key =>
      ({
        'labelText': this.thirdpartyDocumentsCaasContent.navigation[key].optionLabel,
        'href': this.thirdpartyDocumentsCaasContent.navigation[key].optionUrl || ''
      })
      );

      this.actionButtonsContent = Object.keys(this.thirdpartyDocumentsCaasContent.actionButtons).sort().map(key =>
      ({
        'labelText': this.thirdpartyDocumentsCaasContent.actionButtons[key].optionLabel,
        'href': this.thirdpartyDocumentsCaasContent.actionButtons[key].optionUrl || ''
      })
      );

      this.faqContent = Object.keys(this.thirdpartyDocumentsCaasContent.faqContent).sort().map(key =>
      ({
        'optionKey': this.thirdpartyDocumentsCaasContent.faqContent[key].optionKey,
        'optionValue': this.thirdpartyDocumentsCaasContent.faqContent[key].optionValue
      })
      );
      const tempErrorArr = Object.keys(this.thirdpartyDocumentsCaasContent.errorObject).sort().map(key =>
      ({
        'value': this.thirdpartyDocumentsCaasContent.errorObject[key].optionValue,
        'key': this.thirdpartyDocumentsCaasContent.errorObject[key].optionKey
      })
      );
      this.errorData = Object.assign({}, ...(tempErrorArr.map(item => ({ [item.key]: item.value }))));
      this.ftnMessage = this.thirdpartyDocumentsCaasContent.ftnAdvise;
    } else {
      this.shouldShowErrorBannerForCaas = true;
      this.contentBodyForCaasError = "The information is currently unavailable. Please try again later";
    }
  }

  async getThirdPartyDocumentsAddressList() {
    try {
      const [addressListResponse, accountsData] = await Promise.all([this.getThirdPartyDocuments(), this.getAccountsData()])
      let allAccounts = accountsData.accounts;
      // console.log(allAccounts)
      const addresses = addressListResponse.addresses;
      addresses.map((row) => {
        row['linkedAccounts'] = row['authorizedAccountFunds'] || []
      })
      allAccounts.map(value => {
        value.sortingState = 'descending';
        if (value.productType.includes('BROKERAGE')) {
          value.activeSort = 'ticker';
          // value.isChecked = false;
          let counter = 0;
          for (let i = 0; i < addresses.length; i++) {
            if (addresses[i]?.linkedAccounts?.find((obj) => obj.accountId === value.brokerageAccountNumber)) {
              counter++;
            }
          }
          value.noOfLinkedAddresses = counter;
        } else if (value.productType.includes('MUTUAL_FUND')) {
          value.activeSort = 'vanguardFundId';
          value.noOfLinkedAddresses = 0;
          for (let i = 0; i < value.positions.length; i++) {
            let mutualFundCounter = 0;
            // value.positions[i].isChecked = false;
            for (let j = 0; j < addresses.length; j++) {
              if (addresses[j]?.linkedAccounts?.find((obj) => (obj.vastAccountNumber === value.positions[i].vastAccountNumber && obj.vanguardFundId === value.positions[i].vanguardFundId))) {
                mutualFundCounter++;
              }
            }
            value.positions[i].noOfLinkedAddresses = mutualFundCounter;
          }
          if ((value.positions.filter((obj: any) => obj.noOfLinkedAddresses < 9)).length > 0) value.noOfLinkedAddresses = 0
          else value.noOfLinkedAddresses = 9
        }
      })
      allAccounts = allAccounts.filter(acc => acc.productType.includes('BROKERAGE') || acc.productType.includes('MUTUAL_FUND'))
      this.store.dispatch(
        sendAccounts({ accounts: { accounts: allAccounts } })
      );
      this.isLoaderIndicate = false;
      this.thirdPartyDocAddressList = addressListResponse.addresses.filter(el => el.addressType !== 'SPECIALPAYEE');
      this.thirdPartySpecialPayeeAddressList = addressListResponse.addresses.filter(el => el.addressType === 'SPECIALPAYEE');
      this.is529BtnEnabled = accountsData.has529Account;
      if (addressListResponse.errors.length > 0) {
        this.shouldShowErrorBanner = true;
        this.contentBody = this.errorData['ERROR_002'] || this.errorData[this.defaultErrorCode]
      }
    } catch (error) {
      // console.log(error);
      this.shouldShowErrorBanner = true;
      this.contentBody = this.errorData['ERROR_001'] || this.errorData[this.defaultErrorCode];
      this.isLoaderIndicate = false;
    }
  }


  private getThirdPartyDocuments(): Promise<ThirdPartyDocumentsResponse> {
    return firstValueFrom(this.thirdPartyDocumentsService.getThirdPartyAddressList())
  }

  private getAccountsData(): Promise<any> {
    return firstValueFrom(this.thirdPartyDocumentsService.getAccounts())
  }

  actionSelected() {
    // document.location.href = event.href
    this.store.dispatch(
      updateAddress({
        address: {
          name: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          stateCode: '',
          foreign: false,
          foreignCountryCode: '',
          foreignCountryName: '',
          linkedAccounts: []
        }
      })
    );
    this._router.navigate(['/address']);
    this.store.dispatch(
      updateCurrentStep({ currentStepIndex: 1 })
    );

  }
  openWindow(href: string) {
    window.open(href, '_blank', 'menubar=no,toolbar=yes,directories=no,location=no,status=no,copyhistory=yes');
  }

  handleClick = (event: any) => {
    const expandedState = event.currentTarget.getAttribute('aria-expanded') === 'true';
    event.currentTarget.setAttribute('aria-expanded', !expandedState);
  }

  changeAddress(linkedAccounts) {
    this.updateAddressStore(linkedAccounts);
    this.store.dispatch(
      updateCurrentStep({ currentStepIndex: 1 })
    );
    this._router.navigate(['/address']);
  }

  deleteAddress(linkedAccounts) {
    // console.log(linkedAccounts)
    this.updateAddressStore(linkedAccounts);
    this._router.navigate(['/manage-address/delete']);
  }
  viewAccounts(linkedAccounts) {
    // console.log(linkedAccounts)
    this.updateAddressStore(linkedAccounts);
    this._router.navigate(['/manage-address/view']);
  }
  private updateAddressStore(linkedAccounts: any) {
    this.store.dispatch(
      updateAddress({
        address: {
          name: linkedAccounts.name,
          addressLine1: linkedAccounts.addressLine1,
          addressLine2: linkedAccounts.addressLine2,
          city: linkedAccounts.city,
          stateCode: linkedAccounts.foreign ? '' : linkedAccounts.stateCode,
          zipCode: linkedAccounts.foreign ? '' : linkedAccounts.zipCode,
          zipCodePlusFour: linkedAccounts.foreign ? '' : linkedAccounts.zipCodePlusFour,
          foreign: linkedAccounts.foreign,
          foreignCountryCode: linkedAccounts.foreign ? linkedAccounts.foreignCountryCode : '',
          foreignCountryName: linkedAccounts.foreign ? linkedAccounts.foreignCountryName : '',
          linkedAccounts: linkedAccounts.linkedAccounts
        }
      })
    );
  }
}
