import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { StepIndicatorModel } from "@app/state/models/add-address.model";
import { selectStepIndicatorIndex } from "../../../state/selectors/add-address.selector";
import { loadUSStates, loadCountiresList } from "../../../state/actions/add-address.action";

@Component({
  selector: 'app-add-address-master',
  templateUrl: './add-address-master.component.html',
  styleUrls: ['./add-address-master.component.scss']
})
export class AddAddressMasterComponent implements OnInit {
  currentStepIndicatorIndex$: Observable<number>;

  constructor(private _router: Router, private store: Store<StepIndicatorModel>) {
    this.store.dispatch(loadUSStates());
    this.store.dispatch(loadCountiresList());
  }

  ngOnInit(): void {
    this.currentStepIndicatorIndex$ = this.store.pipe(select(selectStepIndicatorIndex));
    this.currentStepIndicatorIndex$.pipe().subscribe(() => {
      // console.log('master component', data)
    });
    // this.currentStepIndicatorIndex$.subscribe((val) => console.log(val));
    // this.store.subscribe((store)=>console.log(store))
  }
}
