import { RuntimeEnvironments, MSAEnvironments } from './runtime-environments';

export const environment = {
  production: true,
  performance: false,
  internal: false,
  deployment: 'production',
  site: 'retailExternal',
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  SYS_LEVEL: 'PROD',
  GLOBAL_HEADER_STYLES: 'https://ccc.vgcontent.info/vg-vgn/latest/css/vgn.min.css?appPrefix=TPQ',
  GLOBAL_HEADER_JS: 'https://ccc.vgcontent.info/vg-vgn/latest/js/vgn.min.js?appPrefix=TPQ',
  GLOBAL_HEADER_TAXONOMY: 'https://rgh.ecs.retp.c1.vanguard.com/taxonomy',

  LOGON_URL: 'https://logon.vanguard.com/logon?TARGET_OVERRIDE=https://thirdparty-documents.web.vanguard.com',
  MEDALLIA_LIB:
    'https://mdl-medallia-digital.vanguard.com/pkg/1.0.0/web/external_libs/wdcusprem/2940/onsite/medallia-digital-embed.js',
  SECURE_MESSAGE_URL: 'https://personal.vanguard.com/us/SMCInformationCenter',
  // CONSUMER_API: 'https://api.ecs.us-east-1.rett.c1.vanguard.com/xs1-secure-site-consumer-api/',
  // GRAPHQL_URI: 'https://apps.ecs.retp.c1.vanguard.com/xs1-secure-site-consumer-api/graphql',
  LEGACY_URL: 'https://personal.vanguard.com/us/AddrViewController',
  GATEKEEPER_FEATURE_ID: 'TPDLegacyRedirect',

  FLOODLIGHT_RTE: RuntimeEnvironments.PROD,
  MSA_ENVIRONMENT: MSAEnvironments.PRODUCTION,
  PERSONAL_DOMAIN: 'https://personal.vanguard.com',
  ADOBE_TARGET_ANTIFLICKER_JS:
    'https://corp.at2.assets.vgdynamic.info/files/js/core/antiflicker.min.js',
  C3_SERVERLESS_BUNDLE_JS:
    "https://mc3-static-assets.web.vanguard.com/vg-ccc-serverless/latest/vg-ccc-serverless.js",

  THIRDPARTY_DOCUMENTS: {
    BASE_URL: '//apps.ecs.retp.c1.vanguard.com/tpe-consumer-webapp',
    CONSUMER_URL: '/thirdparty-documents/addresses',
    STATES: '/thirdparty-documents/states',
    COUNTRIES: '/thirdparty-documents/countries',
    LOGGER: '/client-log',
    CAAS_URL: '/thirdparty-documents/caas',
    TEALEAF_LIB: '/tealeaf',
    GATEKEEPER_URL: '/gatekeeper',
    ADD_ADDRESS: '/thirdparty-documents/addaddress',
    MANAGE_ADDRESS: '/thirdparty-documents/manageaddress',
    ACCOUNTS: '/thirdparty-documents/accounts',
  }
};
