import { Component, OnInit } from '@angular/core';
import { select, Store } from "@ngrx/store";
import { AddressModel, StepIndicatorModel } from "@app/state/models/add-address.model";
import {
  selectAddAddress, selectConfirmationNumber,
  selectSendAccounts,
  selectStepIndicatorIndex
} from "../../../state/selectors/add-address.selector";
import { Observable } from "rxjs";
import {Account} from "@app/models/thirdparty-documents.model";

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  currentStepIndicatorIndex$: Observable<number>;
  address$: Observable<AddressModel>;
  accounts;
  private allAccounts$: Observable<Account[]>;
  confirmationNumber$: Observable<string>;
  constructor(
    private store: Store<StepIndicatorModel>
  ) { }

  ngOnInit(): void {
    // this.currentStepIndicatorIndex$ = this.store.select((state:any) => (state.stepIndicatorReducer.currentStepIndex));
    this.currentStepIndicatorIndex$ = this.store.pipe(select(selectStepIndicatorIndex));
    this.confirmationNumber$ = this.store.pipe(select(selectConfirmationNumber))
    this.address$ = this.store.pipe(select(selectAddAddress))
    this.address$.pipe().subscribe(() => {
      // console.log(data)
    });
    this.allAccounts$ = this.store.pipe(select(selectSendAccounts))
    this.allAccounts$.pipe().subscribe((data:any) => {
      // console.log(data.accounts)
      this.accounts = JSON.parse(JSON.stringify(data.accounts));
      this.accounts = this.accounts.filter(account => {
        if (account.productType.includes('BROKERAGE') && account.isChecked === true) {
          return account
        } else {
          account.positions = account.positions.filter(pos => {
            if(pos.isChecked === true) return pos
          })
          if(account.positions.length>0) return account
        }
      })
      // console.log(this.accounts)
    });
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  handleClick = (event: any) => {
    const expandedState = event.currentTarget.getAttribute('aria-expanded') === 'true';
    event.currentTarget.setAttribute('title', expandedState ? 'show more' : 'show less');
    event.currentTarget.setAttribute('aria-expanded', !expandedState);
    if (!expandedState) {
      event.currentTarget.parentElement.parentElement.classList.add('c11n-accordion--expanded');
    } else {
      event.currentTarget.parentElement.parentElement.classList.remove('c11n-accordion--expanded');
    }
  };
}
