import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { StoreModule } from "@ngrx/store";
import {
  addAddressReducer,
  usStatesReducer,
  countriesListReducer,
  confirmationNumberReducer
} from "@app/state/reducers/add-address.reducer";
import { AddAddressComponent } from "@app/components/add-address-home/add-address/add-address.component";
import {
  SelectAccountsFundsComponent
} from "@app/components/add-address-home/select-accounts-funds/select-accounts-funds.component";
import { BrokerageTypeComponent } from "@app/components/add-address-home/brokerage-type/brokerage-type.component";
import { ReviewAndSubmitComponent } from "@app/components/add-address-home/review-and-submit/review-and-submit.component";
import { ConfirmationComponent } from "@app/components/add-address-home/confirmation/confirmation.component";
import { StepIndicatorComponent } from "@app/components/step-indicator/step-indicator.component";
import { FormsModule } from "@angular/forms";
import { LinkModule } from "@vg-constellation/angular-15/link";
import { HttpClientModule } from "@angular/common/http";
import { IconModule } from "@vg-constellation/angular-15/icon";
import { NavOverflowModule } from "@vg-constellation/angular-15/nav-overflow";
import { SpinnerModule } from "@vg-constellation/angular-15/spinner";
import { CardComponent } from "@vg-constellation/angular-15/card";
import { ButtonModule } from "@vg-constellation/angular-15/button";
import { StepIndicatorModule } from "@vg-constellation/angular-15/step-indicator";
import { LabelModule } from "@vg-constellation/angular-15/label";
import { HintErrorModule } from "@vg-constellation/angular-15/hint-error";
import { InputModule } from "@vg-constellation/angular-15/input";
import { IMaskModule } from "angular-imask";
import { SelectModule } from "@vg-constellation/angular-15/select";
import { CheckboxModule } from '@vg-constellation/angular-15/checkbox';
import { AddAddressHomeRoutingModule } from "@app/components/add-address-home/add-address-home-routing.module";
import { AddAddressMasterComponent } from './add-address-master/add-address-master.component';
import { RadioModule } from '@vg-constellation/angular-15/radio';
import { ModalDialogModule } from '@vg-constellation/angular-15/modal-dialog';


@NgModule({
  declarations: [AddAddressComponent, SelectAccountsFundsComponent, BrokerageTypeComponent, ReviewAndSubmitComponent, ConfirmationComponent, StepIndicatorComponent, AddAddressMasterComponent],
  imports: [
    CommonModule,
    LinkModule, HttpClientModule, IconModule,
    NavOverflowModule, SpinnerModule, CardComponent,
    NgOptimizedImage, ButtonModule, StepIndicatorModule,
    LabelModule, HintErrorModule, InputModule, IMaskModule, FormsModule, SelectModule, CheckboxModule, RadioModule,
    AddAddressHomeRoutingModule,
    ModalDialogModule.forRoot(),
    StoreModule.forFeature('currentStepIndex', addAddressReducer),
    StoreModule.forFeature('allUSStates', usStatesReducer),
    StoreModule.forFeature('allCountiresList', countriesListReducer),
    StoreModule.forFeature('confirmationNumber', confirmationNumberReducer),
  ],
  exports: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AddAddressHomeModule { }
