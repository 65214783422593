import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class GatekeeperService {
  constructor(private readonly http: HttpClient, private readonly router: Router) {}

  public isFeatureSwitchedOn(featureId: string): Observable<boolean> {
    const url =  `${environment.THIRDPARTY_DOCUMENTS.BASE_URL}${environment.THIRDPARTY_DOCUMENTS.GATEKEEPER_URL}/${featureId}`;
    return this.http
      .get<any>(url, { withCredentials: true })
      .pipe(
        map((isToggle) => {
          return isToggle;
        }),
        catchError(() => {
          return of(false);
        }),
      );
  }

  public redirectToModernizedApp(): void {
    this.router.navigate(['']);
  }
}
