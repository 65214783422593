// import the interface
import {AddAddressModel, USStates, CountriesModel, ConfirmationModel} from '../models/add-address.model';
import {
  loadUSStatesSuccess,
  sendAccounts,
  updateAddress,
  updateCurrentStep,
  loadCountiresListSuccess,
  updateConfirmationNumber
} from '../actions/add-address.action';
import { createReducer, on } from '@ngrx/store';
//create a dummy initial state
const initialState: AddAddressModel = {
  stepIndicator: {
    currentStepIndex: 1
  },
  address: {
    name: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    stateCode: '',
    foreign: false,
    foreignCountryCode: '',
    linkedAccounts: []
  },
  accounts: {
    accounts: [{
      accountId: "",
      accountName: "",
      brokerageAccountNumber: "",
      productType: "",
      noOfLinkedAddresses: 0,
      positions: [
        {
          fundName: "",
          symbol: "",
          vanguardFundId: "",
          vastAccountNumber: "",
          noOfLinkedAddresses: 0,
        }
      ]
    }]
  }
}
const initialUSStates: USStates[] = [];
const initialCountriesList: CountriesModel[] = [];
const initialConfirmationNumberState: ConfirmationModel = {
  confirmationNumber: ''
}
export const addAddressReducer = createReducer(
  initialState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  on(updateCurrentStep, (state, action) => (

    { ...state, currentStepIndex: action.currentStepIndex }
  )),
  on(updateAddress, (state, action) => (

    { ...state, address: action.address }
  )),
  on(sendAccounts, (state, action) => (

    { ...state, accounts: action.accounts }
  ))
);

export const usStatesReducer = createReducer(
  initialUSStates,
  on(loadUSStatesSuccess, (state, action) => (
    { ...state, allStates: action.allUSStates.filter((item:any) => item.stateDependencyCode === 'STAT') }
  )),
);

export const countriesListReducer = createReducer(
  initialCountriesList,
  on(loadCountiresListSuccess, (state, action) => (
    { ...state, countriesList: action.countriesList.filter((item:any) => item.active === 'A') }
  )),
);

export const confirmationNumberReducer = createReducer(
  initialConfirmationNumberState,
  on(updateConfirmationNumber, (state, action) => (

    { ...state, confirmationNumber: action.confirmationNumber }
  )),
);
